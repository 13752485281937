import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import DataEncrypt from './DataEncrypt';
import { useTranslation } from 'react-i18next';

export const dataLink = process.env.REACT_APP_DATA;
function Register() {
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    phone: '',
    findus1: '',
    dob: '',
    source: '',
    pin: '',
    referralID: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState('');
  const { name,  password, phone, pin, referralID } = formData;

  useEffect(() => {
    setIsVisible(source === 'referral');
  }, [source]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const domain = window.location.hostname;
    const ag = urlParams.get('ag');
    if (ag) {
      Cookies.set('ag', ag, { expires: 1 });
    }

    const from = urlParams.get('from');
    if (from) {
      Cookies.set('from', from, { expires: 1 });
      if (from === 'referral') {
        setIsVisible(true);
        setFormData(prevState => ({ ...prevState, source: 'referral' }));
      } else if (from === 'postersBanners') {
        setFormData(prevState => ({ ...prevState, source: 'postersBanners' }));
      }
    }

    const ref = urlParams.get('ref');
    if (ref) {
      const decodedRef = atob(ref);
      const [refUsername, refCode] = decodedRef.split('-');
      Cookies.set('ref', refCode, { expires: 1 });
      setFormData(prevState => ({ ...prevState, referralID: refUsername }));
    }
  }, []);


  useEffect(() => {
    const body = document.querySelector('body');
    if (isLoggedIn) {
      body.classList.add('isLoggedIn');
    } else {
      body.classList.remove('isLoggedIn');
    }

    const handleClick = (event) => {
      if (event.target.classList.contains('modal-close')) {
        document.querySelectorAll('input').forEach(input => input.value = '');
        document.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false);
        document.querySelectorAll('select').forEach(select => select.selectedIndex = 0);
        setErrorMessage('');
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const agValue = Cookies.get('ag') || 'SG8T';

  const validatePassword = (password) => {
    if (password.length < 6 || password.length > 8) {
      return `${t('errorsMsg.3')}`;
    }
  
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    if (!hasUpperCase || !hasLowerCase) {
      return `${t('errorsMsg.4')}`;
    }
  
    const hasNumber = /[0-9]/.test(password);
    if (!hasNumber) {
      return `${t('errorsMsg.5')}`;
    }
  
    return null;
  };
  
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const handleRegister = async () => {
    if (pin) {

      if (pin.length < 3) {
        setErrorMessage('PIN must be at least 3 characters long.');
        return;
      }

      if (pin.length > 25) {
        setErrorMessage('PIN must be no more than 25 characters long.');
        return;
      }
      const alphanumericPattern = /^[a-zA-Z0-9]+$/;
      if (!alphanumericPattern.test(pin)) {
        setErrorMessage('PIN must contain only alphabetic characters and numbers.');
        return;
      }
    }
    const passwordError = validatePassword(password);
    if (passwordError) {
      setErrorMessage(passwordError);
      return;
    }
    
    // if (!isGovtEmployee) {
    //   setErrorMessage("Government employees or officials are not allowed to register.");
    //   return;
    // }
    
    if (!source) {
      setErrorMessage(`${t('errorsMsg.6')}`);
      return;
    }

    // Switch statement to change source value
  let sourceValue = source;
  switch (source) {
    case 'fb':
    case 'fbads':
      sourceValue = 'Facebook Ads';
      break;
    case 'instagram':
      sourceValue = 'Instagram';
      break;
    case 'twitter':
      sourceValue = 'Twitter';
      break;
    case 'tiktok':
      sourceValue = 'Tiktok';
      break;
    case 'google':
      sourceValue = 'Google Search';
      break;
    case 'googleAds':
      sourceValue = 'Google Ads';
      break;
    case 'referral':
      sourceValue = 'Friend Referral';
      break;
    case 'sms':
      sourceValue = 'SMS';
      break;
    case 'postersBanners':
      sourceValue = 'Posters/Banners';
      break;
    case 'others':
      sourceValue = 'Others';
      break;
    default:
      break;
  }
  
  setLoading(true);
  try {
    const response = await fetch(dataLink, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      body: new URLSearchParams({
        cmd: "register",
        ag: agValue,
        name: name,
        password: password,
        phone: phone,
        pin: pin,
        domain: "thai.sg8game.com",
        from: sourceValue,
        findus1: 'referral',
        findus2: referralID,
      })
    });
    
    setLoading(false); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      if (data.s === "s") {
        setErrorMessage('Registration successful:');
        setIsLoggedIn(true);
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', data.u);
        window.location.href = "/"; 
      } else {
        setErrorMessage(data.emsg || "Registration failed. Please try again.");
      }
    } catch (error) {
      setErrorMessage("There was a problem with the registration operation. Please try again.");
      setLoading(false); 
    }

  };

  const messageClass = errorMessage === 'Registration successful:' ? 'smsg' : 'emsg';

  return (
    <div>
      <div className='form-register'>
        <img className='formlogo' src='/images/SG8-logo.png' alt='SG8Bet Logo' />
        <label>{t('register.1')}</label>
        <input type="text" name='name' onChange={handleInputChange} placeholder={t('register.8')} />
        <label>{t('register.3')}</label>
        <input type="password" name='password' onChange={handleInputChange}  placeholder={t('register.3')} />
        <label>{t('register.4')}</label>
        <input type="tel" name='phone' onChange={handleInputChange}  placeholder={t('register.4')} />
        <label>{t('register.9')}</label>
        <input type="text" name='pin' onChange={handleInputChange}  placeholder={t('register.9')} />
        <label>{t('register.5')}</label>
        <select value={source} onChange={(e) => setSource(e.target.value)}>
          <option value="">--- Select ---</option>
          <option value="postersBanners">Posters/Banners</option>
          <option value="google">Google Search</option>
          <option value="referral">Friend / Agent ID</option>
          <option value="others">Others</option>
        </select>
        {isVisible && (
          <div id='refid'>
            <label>Referral ID</label>
            <input
              type="text"
              name='referralID'
              value={referralID}
              onChange={handleInputChange}
              placeholder='Referral ID'
            />
          </div>
        )}
        {/* <label>
          <input
            type="checkbox"
            onChange={(e) => setIsGovtEmployee(e.target.checked)}
          />
          I am not a government official or employee connected directly with the operation of the Government or any of its agencies, member of the Armed Forces of the Philippines, including the Army, Navy, Air Force, or the Philippine National Police.
        </label> */}
        <button onClick={handleRegister}>{t('header.2')}</button>
        {errorMessage && <p className={messageClass}>{errorMessage}</p>}
      </div>
      {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
    </div>
  );
}

export default Register;