import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';
import { useTranslation } from 'react-i18next';


function Pgsoft() {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [sliderCurrentPage, setSliderCurrentPage] = useState('PGSoft');
    const [bannerCurrentPage, setBannerCurrentPage] = useState('PGSoft');
    const [searchQuery, setSearchQuery] = useState("");
    const { t, i18n } = useTranslation();

    const pgsoftFilterOptions = [
        { value: 'all', label: t("filter.1") },
        { value: 'top', label: t("filter.8") },
        { value: 'featured', label: t("filter.2") },
        { value: 'slots', label: t("filter.3") },
        { value: 'table', label: t("filter.4") },
        { value: 'arcade', label: t("filter.5") },
    ];
    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8Bet</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Discover top online slot games of 2024 with PGSoft at SG8Bet. Enjoy cutting-edge slots and exciting gameplay."/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
                    <SlotProviderList initialProvider="pgsoft" />
                    <div className="slot-filter-search-wrapper">
                        <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                        <div className="filter-slots">
                            <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={pgsoftFilterOptions} />
                        </div>
                    </div>
                </div>
            </div>
            <GameList provider="pgsoft" filter={currentFilter} searchQuery={searchQuery} />
        </>
    );
}

export default Pgsoft;
