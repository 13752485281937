import React from 'react';
import { Helmet } from "react-helmet";

export default function AboutUs() {
  const contact = "tel:+09171042422";
  const currentPath = window.location.pathname;
  const currentDomain = window.location.origin;

  return <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8Bet</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Welcome to SG8Bet, your premier online gaming destination for 2024. Enjoy top-quality games, exciting bonuses, and a safe, fair gaming environment!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <section className="aboutUs">
                <div className="page-headlines-container">
                    <div className="page-h2-text-style">
                        <h1>We Enabled, Trusted. Regulated</h1>
                    </div>
                </div>
                <p>SG8 prides itself in being a PAGCOR licensed Online Casino and Sportsbook, offering a wide variety of gaming products such as Online Slots Casinos, Sportsbooks, and Fishing Games. We commit ourselves to providing our players with a nonpareil array of entertainment solutions of only the finest quality.</p>
                <p>We curate the ultimate gaming experience for our players through the intertwining of elements of innovation, ambiance, state-of-the-art technology, and extraordinary services. SG8 highly values our players’ peace of mind, and thus safeguards the interests of all players by ensuring added measures are in place to create a safe and regulated environment in line with the regulations set forth by PAGCOR, for all.</p>
                <p>Thank you for choosing SG8 as your preferred gaming platform. We hope that you will enjoy an experience like no other.</p>
            </section>
            <section className="contactUs">
                <div className="page-headlines-container">
                    <div className="page-h2-text-style">
                        <h2>Contact Information</h2> 
                    </div>
                </div>
                <p>You can chat with our friendly Customer Support Representatives via our 24/7 Live Chat function by clicking on the chat icon at the bottom right of the page. Alternatively, you may contact us via the following options listed below.</p>
                <ul className="contactUsNum">
                    <li><a href={contact}><img src="/images/icons/contact.svg" alt="" /><span>Phone</span></a></li>
                    <li><a href={contact}><img src="/images/icons/whatsapp.svg" alt="" /><span>Whatsapp</span></a></li>
                    <li><a href={contact}><img src="/images/icons/viber.svg" alt="" /><span>Viber</span></a></li>
                    <li><a href={contact}><img src="/images/icons/telegram.svg" alt="" /><span>Telegram</span></a></li>
                    <li><a href={contact}><img src="/images/icons/email.svg" alt="" /><span>Email</span></a></li>
                </ul>
            </section>
  </>;
}
