import React, { useState, useEffect } from 'react';
import { apiJSONURL } from '../pages/LiveCasino';
function Verification() {
  const [userDetails, setUserDetails] = useState({
    placeOfBirth: '',
    currentAddress: '',
    permanentAddress: '',
    nationality: '',
    natureOfWork: '',
    typeOfIndustry: '',
    sourceOfIncome: '',
    idType: '',
    idNumber: '',
    photoStatus: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [copyCurrentAddress, setCopyCurrentAddress] = useState(false);

  useEffect(() => {
    const getPlayerDetails = async () => {
      try {
        const username = localStorage.getItem('username').trim();
        const encodedUsername = btoa(username);
        const response = await fetch(apiJSONURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            cmd: 'getPlayerDetails',
            u: encodedUsername
          }).toString()
        });

        const data = await response.json();
        setUserDetails({
          placeOfBirth: data.placeOfBirth || '',
          currentAddress: data.currentAddress || '',
          permanentAddress: data.permanentAddress || '',
          nationality: data.nationality || '',
          natureOfWork: data.natureOfWork || '',
          typeOfIndustry: data.typeOfIndustry || '',
          sourceOfIncome: data.sourceOfIncome || '',
          idType: data.idType || '',
          idNumber: data.idNumber || '',
          photoStatus: data.photoStatus || ''
        });
      } catch (error) {
        setError("Error fetching player details");
      }
    };

    getPlayerDetails();
  }, []);

  useEffect(() => {
    if (copyCurrentAddress) {
      setUserDetails(prevDetails => ({
        ...prevDetails,
        permanentAddress: prevDetails.currentAddress
      }));
    }
  }, [copyCurrentAddress, userDetails.currentAddress]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    setError(null);
    setMessage(null);

    try {
      const username = localStorage.getItem('username').trim();
      const encodedUsername = btoa(username);

      const formData = new FormData(event.target);
      formData.append('cmd', 'verifyAccount');
      formData.append('u', encodedUsername);

      const formParams = new URLSearchParams();
      for (const [key, value] of formData.entries()) {
        formParams.append(key, value);
      }

      const response = await fetch(apiJSONURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formParams.toString()
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.s) {
        setMessage(data.s);
      } else {
        setError(data.e);
      }

      setUserDetails(prevDetails => ({
        ...prevDetails,
        ...data
      }));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return(
    <div>
      <form className="verificationform" onSubmit={handleFormSubmit}>
        <div className="page-h2-text-style" bis_skin_checked="1">
          <h1>Verification</h1>
          <span>Verify your SG8.Casino account</span>
        </div>
        <div className="formrow">
          <label htmlFor="placeOfBirth">Place of Birth:</label>
          <input type="text" name="placeOfBirth" id="placeOfBirth" maxLength="100" placeholder="Place of Birth" value={userDetails.placeOfBirth} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <label htmlFor="currentAddress">Current Address</label>
          <input type="text" name="currentAddress" id="currentAddress" maxLength="100" placeholder="Current Address" value={userDetails.currentAddress} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <label htmlFor="permanentAddress">Permanent Address</label>
          <input type="text" name="permanentAddress" id="permanentAddress" maxLength="100" placeholder="Permanent Address" value={copyCurrentAddress ? userDetails.currentAddress : userDetails.permanentAddress} onChange={handleInputChange}/>
        </div>
        <div className="formrow" style={{ flexFlow: 'row', paddingTop: '8px', paddingLeft: '8px' }}>
          <input type="checkbox" id="copyCurrentAddress" checked={copyCurrentAddress} onChange={() => setCopyCurrentAddress(!copyCurrentAddress)}/>
          <label htmlFor="copyCurrentAddress" style={{ width: '100%', fontSize: '.9em', paddingLeft: '8px', marginTop: '0px' }}>
            Same with current address
          </label>
        </div>
        <div className="formrow">
          <label htmlFor="nationality">Nationality</label>
          <select name="nationality" id="nationality" className="selectSite" value={userDetails.nationality} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Filipino</option>
            <option>Afghan</option>
            <option>Algerian</option>
            <option>American</option>
            <option>Angolan</option>
            <option>Argentine</option>
            <option>Australian</option>
            <option>Austrian</option>
            <option>Bangladeshi</option>
            <option>Belarusian</option>
            <option>Belgian</option>
            <option>Bolivian</option>
            <option>Bosnian/Herzegovinian</option>
            <option>Brazilian</option>
            <option>British</option>
            <option>Bulgarian</option>
            <option>Cambodian</option>
            <option>Cameroonian</option>
            <option>Canadian</option>
            <option>Central African</option>
            <option>Chadian</option>
            <option>Chinese</option>
            <option>Colombian</option>
            <option>Congolese</option>
            <option>Costa Rican</option>
            <option>Croatian</option>
            <option>Czech</option>
            <option>Danish</option>
            <option>Dutch</option>
            <option>Ecuadorian</option>
            <option>Egyptian</option>
            <option>Emirati</option>
            <option>English</option>
            <option>Estonian</option>
            <option>Ethiopian</option>
            <option>Finnish</option>
            <option>French</option>
            <option>German</option>
            <option>Ghanaian</option>
            <option>Greek</option>
            <option>Guatemalan</option>
            <option>Honduran</option>
            <option>Hungarian</option>
            <option>Icelandic</option>
            <option>Indian</option>
            <option>Indonesian</option>
            <option>Iranian</option>
            <option>Iraqi</option>
            <option>Irish</option>
            <option>Israeli</option>
            <option>Italian</option>
            <option>Ivorian</option>
            <option>Jamaican</option>
            <option>Japanese</option>
            <option>Jordanian</option>
            <option>Kazakh</option>
            <option>Kenyan</option>
            <option>Lao</option>
            <option>Latvian</option>
            <option>Libyan</option>
            <option>Lithuanian</option>
            <option>Malagasy</option>
            <option>Malaysian</option>
            <option>Malian</option>
            <option>Mauritanian</option>
            <option>Mexican</option>
            <option>Moroccan</option>
            <option>Namibian</option>
            <option>New Zealand</option>
            <option>Nicaraguan</option>
            <option>Nigerian</option>
            <option>Nigerien</option>
            <option>Norwegian</option>
            <option>Omani</option>
            <option>Pakistani</option>
            <option>Panamanian</option>
            <option>Paraguayan</option>
            <option>Peruvian</option>
            <option>Polish</option>
            <option>Portuguese</option>
            <option>Romanian</option>
            <option>Russian</option>
            <option>Salvadoran</option>
            <option>Saudi, Saudi Arabian</option>
            <option>Scottish</option>
            <option>Senegalese</option>
            <option>Serbian</option>
            <option>Singaporean</option>
            <option>Slovak</option>
            <option>Somalian</option>
            <option>South African</option>
            <option>Spanish</option>
            <option>Sudanese</option>
            <option>Swedish</option>
            <option>Swiss</option>
            <option>Syrian</option>
            <option>Thai</option>
            <option>Tunisian</option>
            <option>Turkish</option>
            <option>Turkmen</option>
            <option>Ukranian</option>
            <option>Uruguayan</option>
            <option>Vietnamese</option>
            <option>Welsh</option>
            <option>Zambian</option>
            <option>Zimbabwean</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="natureOfWork">Nature of Work</label>
          <select name="natureOfWork" id="natureOfWork" className="selectSite" value={userDetails.natureOfWork} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Private</option>
            <option>Self employed/professional</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="typeOfIndustry">Type of Industry</label>
          <select name="typeOfIndustry" id="typeOfIndustry" className="selectSite" value={userDetails.typeOfIndustry} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Agriculture, Forestry, Mining</option>
            <option>Industrials (Manufacturing, Construction, etc.)</option>
            <option>Energy and Utilities</option>
            <option>Transport and Logistics</option>
            <option>Media and Creative Industries</option>
            <option>Data Infrastructure and Telecom</option>
            <option>Healthcare</option>
            <option>Education</option>
            <option>Life Sciences</option>
            <option>Retail / ecommerce</option>
            <option>Hospitality, Food and Leisure Travel</option>
            <option>Public Service and Social Service</option>
            <option>Financial Services</option>
            <option>Professional Services (Law, Accounting, Consulting, etc.)</option>
            <option>Others</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="sourceOfIncome">Source of Income</label>
          <select name="sourceOfIncome" id="sourceOfIncome" className="selectSite" value={userDetails.sourceOfIncome} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Salary</option>
            <option>Investment</option>
            <option>Savings</option>
            <option>Professional fees</option>
            <option>Business income</option>
            <option>Pension</option>
            <option>Commission</option>
            <option>Others</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="idType">ID Type</label>
          <select name="idType" id="idType" className="selectSite" value={userDetails.idType} onChange={handleInputChange}>
            <option value="">--- Select ---</option>
            <option>Phil ID</option>
            <option>Passport</option>
            <option>Driver's License</option>
            <option>Professional Regulations Commission (PRC) ID</option>
            <option>Postal ID</option>
            <option>Voter's ID</option>
            <option>Barangay Certification (with photo)</option>
            <option>Tax Identification (TIN)</option>
            <option>Social Security System (SSS) Card</option>
            <option>Senior Citizen Card</option>
            <option>Alien Certificate of Registration/ Immigrant Certificate of Registration</option>
            <option>Unified Multi-purpose ID (UMID)</option>
          </select>
        </div>
        <div className="formrow">
          <label htmlFor="idNumber">ID Number</label>
          <input type="text" name="idNumber" id="idNumber" maxLength="100" placeholder="ID Number" value={userDetails.idNumber} onChange={handleInputChange}/>
        </div>
        <div className="formrow">
          <div id="verify-alert-cont" className="alert alert-info">
            <div id="verify-alert"></div>
          </div>
        </div>
        <div className="formrow">
          <button id="verifyBtn" type="submit">Submit</button>
        </div>
      </form>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}
      {message && <div>Message: {message}</div>}
      
    </div>
  );
}

export default Verification;
