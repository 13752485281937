import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { apiJSONURL } from './pages/LiveCasino';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './Modal/LoginModal';
import Register from './api/Register';
import Verification from './api/Verification';
import UploadPhoto from './api/UploadPhoto';
import { promotionList } from './pages/promotionList';
import { BlogList } from './pages/Blog/Blog';
import { useTranslation } from 'react-i18next';
import Translator from './snippets/Translator';
import LogoutFunction from './snippets/LogoutFunction';

function Header() {
    const { t, i18n } = useTranslation();
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [userData, setUserData] = useState({});
    const [username, setUsername] = useState('');
    const [balance, setBalance] = useState(null);
    const [breakdown, setBreakdown] = useState({});
    const [rewardPoints, setRewardPoints] = useState();
    const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [isVisibleBalance, setIsVisible] = useState(false);
    const [isVisibleAccount, setIsVisibleAccount] = useState(false);
    const [isVisibleVerify, setIsVisibleVerify] = useState(false);
    const [isVisiblePhoto, setIsVisiblePhoto] = useState(false);
    const [activeMenu, setActiveMenu] = useState(window.location.pathname);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [imageSrc, setImageSrc] = useState('');
    const [language, setLanguage] = useState('th');
    const ipread = "https://api.ipify.org?format=json";
    // const geoRead = "https://www.geoplugin.net/json.gp?ip=";
    
    // useEffect(() => {
    //     const fetchIp = async () => {
    //         try {
    //             const ipResponse = await fetch(ipread);
    //             if (ipResponse.ok) {
    //                 const ipData = await ipResponse.json();  
    //                 const geoResponse = await fetch(geoRead + ipData.ip);
    //                 if (geoResponse.ok)
    //                 {
    //                     const geoData = await geoResponse.json();
    //                     // if (geoData.geoplugin_countryName=='Philippines')
    //                     //     {
    //                     //        window.location.href = 'https://sg8.casino/';
    //                     //     }    
    //                     console.log(geoData.geoplugin_countryName);
    //                 }
    //             }
    //         } catch (error) {
    //             console.error('Error fetching user data:', error);
    //         }
    //     }
    //     fetchIp();
    // }, []);


    const handleVerifyClick = () => {
        console.log('handleVerifyClick triggered');
        setIsVisibleVerify(!isVisibleVerify);
    };

    const handlePhotoClick = () => {
        console.log('handlePhotoClick triggered');
        setIsVisiblePhoto(!isVisiblePhoto);
    };

    const handleMenuClick = (path) => {
        setActiveMenu(path);
    };

    useEffect(() => {
        const elements = document.querySelectorAll('#handleVerifyClick');
        elements.forEach(element => {
            element.addEventListener('click', handleVerifyClick);
        });

        return () => {
            elements.forEach(element => {
                element.removeEventListener('click', handleVerifyClick);
            });
        };
    }, []);

    useEffect(() => {
        const elements = document.querySelectorAll('#handlePhotoClick');
        elements.forEach(element => {
            element.addEventListener('click', handlePhotoClick);
        });

        return () => {
            elements.forEach(element => {
                element.removeEventListener('click', handlePhotoClick);
            });
        };
    }, []);

    const handleClose = () => {
        setIsVisibleVerify(false);
        setIsVisiblePhoto(false);
    };

    useEffect(() => {
        const savedUsername = localStorage.getItem('username');
        if (savedUsername) {
            setUsername(savedUsername);
        }
    }, []);

    const handleBreakdownClick = () => {
        setIsVisible(!isVisibleBalance);
        setIsVisibleAccount(false);
    };

    const handleAccountClick = () => {
        setIsVisibleAccount(!isVisibleAccount);
        setIsVisible(false);
    };

    useEffect(() => {
        //const username=localStorage.getItem('encryptedUsername');
        const username = localStorage.getItem('username');
        if (username) {
            const fetchRewards = async () => {
                try {
                    const RewardsResponse = await fetch(`${apiJSONURL}?cmd=getRewards&u=${username}`);
                    const rewardData = await RewardsResponse.json();
                    const rewards = rewardData;
                    if (rewards.rewards!= null){
                        setRewardPoints(rewards.rewards);
                    }
                    else{
                        setRewardPoints("...");
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            fetchRewards();
        }
    }, [triggerFetch]);


    useEffect(() => {
        //const username = localStorage.getItem('encryptedUsername');
        const username = localStorage.getItem('username');
        if (username) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`${apiJSONURL}?cmd=getBalance&u=${username}`);
                    const data = await response.json();
                    const balance = data.balance;
                    setBalance(balance);
                    setBreakdown(data);
                    setUserData({ u: username });
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            fetchUserData();
        }
    }, [triggerFetch]);

    const handleReturnWallet = async () => {
        //const username = localStorage.getItem('encryptedUsername');
        const username = localStorage.getItem('username'); 
        const params = new URLSearchParams({
            cmd: 'withdrawFromAll',
            u: username
        });

        try {
            const response = await fetch(`${apiJSONURL}?${params}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            setTriggerFetch(prevState => !prevState);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const handleRegisterFromLoginClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(true);
    };

    const handleLoginFromRegisterClick = () => {
        setShowRegisterForm(false);
        setShowLoginForm(true);
    };

    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
    };

    const handleCloseClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(false);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng); // Set the selected language state
        if (lng === 'en') {
            setMessage('You are now browsing in English');
            setImageSrc('/images/ukflag.png');
        } else if (lng === 'th') {
            setMessage('คุณกำลังใช้งานเว็บไซต์เป็นภาษาอังกฤษ');
            setImageSrc('/images/thaiflag.png');
        }
        setOpen(true);
    };

    const closeModal = () => setOpen(false);

    useEffect(() => {
        setIsBodyLoggedIn(document.body.classList.contains('isLoggedIn'));
    }, []);

    const promoClicks = promotionList.map(promo => promo.promoClick);
    const blogList = BlogList.map(promo => promo.link);

    return (
        <>
            {isVisibleVerify &&
                <div className='modal verification-container'>
                    <div className='modal-container'><Verification /></div>
                </div>
            }
            {isVisibleVerify &&
                <button className='verification-close modal-close' onClick={handleClose}>✖</button>
            }
            {isVisiblePhoto &&
                <div className='modal verification-container'>
                    <div className='modal-container'><UploadPhoto /></div>
                </div>
            }
            {isVisiblePhoto &&
                <button className='verification-close modal-close' onClick={handleClose}>✖</button>
            }
            <div id="LoginTrigger" className={`modal ${showLoginForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <LoginModal />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showRegisterForm && <div className='HaveAccount'>{t('login.5')} <a onClick={handleRegisterFromLoginClick}>{t('header.2')}</a></div>}
                </div>
            </div>
            <div id="RegTrigger" className={`modal ${showRegisterForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <Register />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showLoginForm && <div className='HaveAccount'>{t('register.6')} <a className='login' onClick={handleLoginFromRegisterClick}>{t('header.1')}</a></div>}
                </div>
            </div>
            <header id="masthead" className="site-header has-logo has-menu">
                <div className="sticky-navigation desktop-view">
                    <div className="navigation-fixed-container">
                        <a href="/"><img className="website-logo" src="/images/sg8-logo.png" alt="official sg8 logo" /></a>
                        <Translator />
                        <div className="user-information">
                            <div className="userwrapper">
                                <span className="userID">
                                    {username}
                                </span>
                                <span className="userBalance">{balance ?? '...'}
                                    <button type="button" className="button" onClick={handleBreakdownClick}>
                                        <FontAwesomeIcon icon={faChevronDown} />
                                    </button></span>
                                    <div className='reward-section'>{t('label.23')}<br/> <span>{rewardPoints}</span></div> 
                                {isVisibleBalance && (<div className='balance-brakedown'>
                                    {Object.keys(breakdown).map(key => {
                                        if (key !== 'data' && key !== 'balance') {
                                            return (
                                                <div key={key}>
                                                    {key}: <span>{breakdown[key]}</span>
                                                </div>
                                            );
                                        } else {
                                            return null; // Exclude these keys from rendering
                                        }
                                    })}
                                    <button onClick={handleReturnWallet}>{t('label.12')}</button><br/>
                                </div>)}
                                
                            </div>

                            <div className="c2a-depo-withdraw">
                                <a href="/account/deposit/" className="depo-btn">{t('header.3')}</a>
                                <a href="/account/withdraw/" className="withdraw-btn">{t('header.4')}</a>
                            </div>
                        </div>
                        <div className="c2a-landingpage">
                            <a onClick={handleLoginClick} className="login-btn">{t('header.1')}</a>
                            <a onClick={handleRegisterClick} className="reg-btn">{t('header.2')}</a>
                        </div>
                        <div className="navigation-menu-list">
                            <div className="primary-menu-list">
                                <div
                                    className={`menu ${activeMenu === '/slots/jili/' ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(/images/slot-navigation-1.webp)` }}
                                    onClick={() => handleMenuClick('/slots/jili/')}
                                >
                                    <a href="/slots/pgsoft/"><span>{t('nav.1')}</span></a>
                                </div>
                                <div
                                    className={`menu ${activeMenu === '/live-casino/' ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(/images/live-dealer-navigation1.webp)` }}
                                    onClick={() => handleMenuClick('/live-casino/')}
                                >
                                    <a href="/live-casino/"><span>{t('nav.2')}</span></a>
                                </div>
                                <div
                                    className={`menu ${activeMenu === '/sportsbook/' ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(/images/sportsbook-navigation.webp)` }}
                                    onClick={() => handleMenuClick('/sportsbook/')}
                                >
                                    <a href="/sportsbook/"><span>{t('nav.3')}</span></a>
                                </div>
                                {/* <div
                                    className={`menu ${activeMenu === '/fishing/' ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(/images/fishing-navigation.webp)` }}
                                    onClick={() => handleMenuClick('/fishing/')}
                                >
                                    <a href="/fishing/"><span>{t('nav.4')}</span></a>
                                </div> */}
                                <div
                                    className={`menu ${activeMenu === '/pk-10/' ? 'active' : ''}`}
                                    style={{ backgroundImage: `url(/images/pk10-navigation.webp)` }}
                                    onClick={() => handleMenuClick('/pk10-keno/')}
                                >
                                    <a href="/pk10-keno/"><span>{t('nav.5')}</span></a>
                                </div>
                            </div>
                            <div className="secondary-menu-list">
                                <ul>
                                    <li className={`.secondary-menu-list li ${['/promotion/', ...promoClicks].includes(activeMenu) ? 'active' : ''} link-nav`}><a href="/promotion/">{t('nav.6')}</a></li>
                                    {/* <li className={`.secondary-menu-list li ${activeMenu === '/vip/' ? 'active' : ''} link-nav`}><a href="/vip/">{t('nav.7')}</a></li> */}
                                    {isBodyLoggedIn && (
                                        <li className={`.secondary-menu-list li ${activeMenu === '/account/request-report/' ? 'active' : ''} link-nav`}><a href="/account/request-report/">{t('nav.8')}</a></li>
                                    )}
                                    {isBodyLoggedIn && (
                                        <li className={`.secondary-menu-list li ${activeMenu === '/account/dashboard/' ? 'active' : ''} link-nav`}><a href="/account/dashboard/">{t('nav.9')}</a></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-header-container mobile-view">
                    <section>
                        <div className="mobile-website-logo">
                            <a href="/">
                                <img className="website-logo" src="/images/sg8-logo.png" alt="official sg8 logo" />
                            </a>
                        </div>
                        {!isBodyLoggedIn && (
                            <>
                                <div className='mobile-login-reg'>
                                    {language !== 'en' && (
                                        <a onClick={() => changeLanguage('en')} >
                                            <img src='/images/ukflag.png' alt='English' />
                                        </a>
                                    )}
                                    {language !== 'th' && (
                                        <a onClick={() => changeLanguage('th')}>
                                            <img src='/images/thaiflag.png' alt='Thai' />
                                        </a>
                                    )}
                                    <a onClick={handleLoginClick} className="login-btn">{t('header.1')}</a>
                                </div>
                                <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                                    <div className='popUpContent translationContent'>
                                        <div className="content">
                                            <img src={imageSrc} alt={message} />
                                            <p>{message}</p>
                                            <button className='yellow-btn' onClick={closeModal}>{t("errorsMsg.2")}</button>
                                        </div>
                                    </div>
                                </Popup>
                            </>
                        )}
                        {isBodyLoggedIn && (
                            <div className='mobile-balance'>
                                <div>
                                    <p>Balance</p>
                                    <span className="userBalance">{balance ?? '...'}</span>
                                </div>
                                {!username.startsWith('SG8JPCP') && (
                                    <div style={{ color: "white" }} onClick={handleBreakdownClick}>
                                        <button style={{ background: "none" }} type="button" className="button btn-toogle-wallet">
                                            <FontAwesomeIcon style={{ color: '#111', fontSize: '20px', padding: "5px" }} icon={faChevronDown} />
                                        </button>
                                    </div>
                                )}
                                
                                {isVisibleBalance && (<div className='balance-brakedown'>
                                    {Object.keys(breakdown).map(key => {
                                        if (key !== 'data' && key !== 'balance') {
                                            return (
                                                <div key={key}>
                                                    {key}: <span>{breakdown[key]}</span>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    <button onClick={handleReturnWallet}>{t('label.12')}</button><br/>
                                    <div>{t('label.23')}: <span>{rewardPoints}</span></div> 
                                    <a href="/account/deposit/" className="depo-btn">{t('header.3')}</a>
                                    <a href="/account/withdraw/" className="withdraw-btn">{t('header.4')}</a>
                                </div>)}
                                <div style={{ color: "white" }}>
                                    <button style={{ background: "none" }} type="button" className="button" onClick={handleAccountClick}>
                                        <FontAwesomeIcon style={{ fontSize: '20px', padding: "5px", color: '#fff' }} icon={faUser} />
                                    </button>
                                </div>
                                {isVisibleAccount && <div className='accountsub'>
                                    <span className='username'>
                                        {username}</span>
                                        {language !== 'en' && (
                                        <button className='langbtn' onClick={() => changeLanguage('en')}> 
                                        <img src="/images/ukflag.png" alt="UK Flag" width="20" height="20" />
                                        <span>English</span>
                                        </button>
                                    )}
                                    {language !== 'th' && (
                                        <button className='langbtn' onClick={() => changeLanguage('th')}> 
                                        <img src="/images/thaiflag.png" alt="Thai Flag" width="20" height="20" />
                                        <span>ไทย</span>
                                        </button>
                                    )}
                                    <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                                        <div className='popUpContent translationContent'>
                                            <div className="content">
                                                <img src={imageSrc} alt={message} />
                                                <p>{message}</p>
                                                <button className='yellow-btn' onClick={closeModal}>{t("errorsMsg.2")}</button>
                                            </div>
                                        </div>
                                    </Popup>
                                    <a href='/account/request-report/'>{t("nav.8")}</a>
                                    <a href='/account/dashboard/'>{t("nav.9")}</a>
                                    <LogoutFunction />
                                </div>}
                            </div>
                        )}
                    </section>
                    {!isBodyLoggedIn && (
                        <div className='HaveAccount'>{t('register.2')}<a onClick={handleRegisterFromLoginClick}>{t('header.2')}</a></div>
                    )}
                </div>
            </header>
        </>
    );
}

export default Header;