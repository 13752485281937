import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Popup from "reactjs-popup";
const tokenURL = process.env.REACT_APP_TOKEN_URL;
const wlpLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;

const FeaturedGameList = () => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [launching, setLaunching] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(19);
    const [animationComplete, setAnimationComplete] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const totalFrames = 77; // The last frame number

    const imageRef = useRef(null);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        // const featuredGamesId = [
        //     "72105", "72085", "72049", "72044","72003", "72110", "55089", "72114", "55086", "58028", "72031", "72064"
        // ];
        const featuredGamesId = [
            "53162", "53763", "37090", "37171", "72049", "72044","72003", "72110", "55089", "72114", "55086", "58028",
        ];
        const featuredGamesName = [
             "Gates of Olympus","Sweet Bonanza 1000","Take The Bank", "Ultimate Golden Dragon Inferno - Hold & Win", "Dreams of Macau", "Candy Burst",
            "Fortune Gods", "Bakery Bonanza", "Yo-Ho Gold!", "Lucky Clover Lady", "Dragon Wealth", "Gem Hunter",
        ];
        const loadFeaturedGames = () => {
            const featuredGames = featuredGamesId.map((gameId, index) => {
                const gameName = featuredGamesName[index];
                const gameImg = gameId === "37090" || gameId === "37171"
                    ? `https://res.sg8.fun/resources/images/WLP/${gameId.substring(0, 2)}/${gameId}.png`
                    : `https://res.sg8.fun/resources/images/WLP/${gameId.substring(0, 2)}/${gameId}.jpg`;
                return {
                    gameId,
                    gameName,
                    gameImg,
                };
            });
            setGames(featuredGames);
            setLoading(false);
        };

        loadFeaturedGames();
    }, []);

    useEffect(() => {
        let interval;
        if (loading || launching) {
            interval = setInterval(() => {
                setCurrentFrame(prevFrame => {
                    if (prevFrame < totalFrames) {
                        return prevFrame + 1;
                    } else {
                        setAnimationComplete(true);
                        clearInterval(interval);
                        return totalFrames;
                    }
                });
            }, 30); // Adjust the interval to 30 milliseconds for 2x speed
        }
        return () => clearInterval(interval);
    }, [loading, launching]);
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };
    const launchGame = async (gameId) => {
        if (!document.body.classList.contains("isLoggedIn")) {
            setPopupMessage(t('msg.1'));
            setIsPopupOpen(true);
            return;
        }

        if (launching) return; // Prevent multiple launches
        setLaunching(true);
        setCurrentFrame(19); // Reset the frame
        setAnimationComplete(false); // Reset animation complete
        if (imageRef.current) {
            imageRef.current.classList.remove('pop');
        }

        try {
            //const username = localStorage.getItem('encryptedUsername');
            const username = localStorage.getItem('username');
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const mobileIndicator = isMobile ? 1 : 0;
            const tokenResponse = await axios.get(`${tokenURL}?u=${username}&g=${gameId}&t=transfer&m=${mobileIndicator}`);
            const sessionToken = tokenResponse.data.token;

            const lang = t('lang.1');
            const url = `${wlpLaunchURL}?s=${sessionToken}&l=${lang}&m=${mobileIndicator}`;
            console.log("Launching game with URL:", url);

            const launchGameAfterAnimation = () => {
                if(!isMobile){
                    const popupWindow = window.open(
                    url, "_blank", "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
                    );
                    if (popupWindow) {
                        popupWindow.focus();
                    } else {
                        setPopupMessage(t('msg.2'));
                        setIsPopupOpen(true);
                    }
                    setLaunching(false);
                }else{
                    const popupWindow = window.open(
                    url, "_self", 
                    );
                    if (popupWindow) {
                        popupWindow.focus();
                    } else {
                        setPopupMessage(t('msg.2'));
                        setIsPopupOpen(true);
                    }
                    setLaunching(false);
                }
            
            };

            if (animationComplete) {
                launchGameAfterAnimation();
            } else {
                setTimeout(() => {
                    launchGameAfterAnimation();
                }, (totalFrames - currentFrame) * 30); // Remaining animation time
            }
        } catch (error) {
            setPopupMessage("Failed to launch game.");
            setIsPopupOpen(true);
            setLaunching(false);
        }
    };

    if (loading) {
        return (
            <section className='loadingGames'>
                <img src="/images/sg8.gif" style={{ width: '200px' }} alt="Loading..." />
            </section>
        );
    }

    const isLoggedIn = document.body.classList.contains("isLoggedIn");
    return (
        <div className='featuredListWrapper'>
            {launching && isLoggedIn && (
                <div className='overlayStyle'>
                    <div className='loadingBar'>
                        <div className={`spinner ${animationComplete ? 'pop' : ''}`}>
                            <img ref={imageRef} src={`/images/frameLoading/sg8logo_${String(currentFrame).padStart(5, '0')}.png`} alt='Loading animation' />
                        </div>
                    </div>
                </div>
            )}
            {games.length > 0 ? (
                games.map((game, index) => (
                    <div key={index} className="gameWrapper">
                        <div className="game" onClick={() => launchGame(game.gameId)}>
                            <div className="gameImg">
                                <img alt={`${game.gameName}`} src={game.gameImg} />
                            </div>
                            <div className='gameDetails'>
                                <div className="gameName">{game.gameName}</div>
                                <button className='yellow-btn'>Play Now</button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <section className='loadingGames'>
                    <div>
                        <img src="/images/sg8-icon.webp" style={{ width: '60px' }} alt="SG8 Icon" />
                        <p>No featured games found.</p>
                    </div>
                </section>
            )}
            <Popup open={isPopupOpen} onClose={() => setIsPopupOpen(false)} modal nested>
                <div className="popupError" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
                    <img src="/images/promo-mobile-icon.webp" alt="" />
                    <div className="content">
                        {popupMessage}
                    </div>
                    <div className="actions">
                        <button className="yellow-btn" onClick={() => setIsPopupOpen(false)}>
                            OK
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default FeaturedGameList;
