import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { currentPath, currentDomain } from "../App";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { launchGame, AlertModal } from "./LiveCasino";

function Pk10Keno() {
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const imageRef = useRef(null);
  const totalFrames = 77;

  const launchPage = () => {
    setPopupMessage(t("msg.1"));
    setIsPopupOpen(true);
    setCurrentFrame(19);
  };
  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(loggedInStatus === "true");
  }, []);

    useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 15);
    }
    return () => clearInterval(interval);
  }, [launching]);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the ultimate PK10 experience!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <h1 className="sportsTitle pk10title">{t("titles.2")}</h1>
          {isLoggedIn ? (
            !isMobile ? (
              <div className="pk10-dashboard">
                <div>
                  <img src="/images/pk10/keno.jpg" alt="" />
                  <div className="pk10Logo">
                    <img src="/images/pk10/gameplay-logo.webp" alt="" />
                  </div>
                  <section className="pk10btn">
                    <div>
                      <a href="/pk10-keno/lottery/" className="yellow-btn">
                        {t("button.4")}
                      </a>
                    </div>
                    <div>
                      <a href="/pk10-keno/keno/" className="yellow-btn">
                        {t("button.5")}
                      </a>
                    </div>
                  </section>
                </div>
                <div>
                  <img src="/images/pk10/pk10.jpg" alt="" />
                  <div className="pk10Logo">
                    <img src="/images/pk10/PK10-logo.png" alt="" />
                  </div>
                  <div>
                    <a href="/pk10-keno/pk10/" className="yellow-btn">
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="pk10-dashboard">
                <div>
                  <img src="/images/pk10/keno.jpg" alt="" />
                  <div className="pk10Logo">
                    <img src="/images/pk10/gameplay-logo.webp" alt="" />
                  </div>
                  {launching && isLoggedIn && (
                    <div className=" overlayStyle">
                      <div className="loadingBar">
                        <div
                          className={`spinner ${
                            animationComplete ? "pop" : ""
                          }`}
                        >
                          <img
                            ref={imageRef}
                            src={`/images/frameLoading/sg8logo_${String(
                              currentFrame
                            ).padStart(5, "0")}.png`}
                            alt="Loading..."
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <section className="pk10btn">
                    <div>
                      <a
                        onClick={() =>
                          launchGame(
                            "",
                            "15511",
                            true,
                            t("lang.1"),
                            0,
                            true,
                            setLaunching,
                            setCurrentFrame,
                            setAnimationComplete,
                            t
                          )
                        }
                        className="yellow-btn"
                      >
                        {t("button.4")}
                      </a>
                    </div>
                    <div>
                      <a
                        onClick={() =>
                          launchGame(
                            "",
                            "15501",
                            true,
                            t("lang.1"),
                            0,
                            true,
                            setLaunching,
                            setCurrentFrame,
                            setAnimationComplete,
                            t
                          )
                        }
                        className="yellow-btn"
                      >
                        {t("button.5")}
                      </a>
                    </div>
                  </section>
                </div>
                <div>
                  <img src="/images/pk10/pk10.jpg" alt="" />
                  <div className="pk10Logo">
                    <img src="/images/pk10/PK10-logo.png" alt="" />
                  </div>
                  <div>
                    <a
                      onClick={() =>
                        launchGame(
                          "",
                          "15521",
                          true,
                          t("lang.1"),
                          0,
                          true,
                          setLaunching,
                          setCurrentFrame,
                          setAnimationComplete,
                          t
                        )
                      }
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="pk10-dashboard">
              <div>
                <img src="/images/pk10/keno.jpg" alt="" />
                <div className="pk10Logo">
                  <img src="/images/pk10/gameplay-logo.webp" alt="" />
                </div>
                <section className="pk10btn">
                  <div>
                    <a onClick={launchPage} className="yellow-btn">
                      {t("button.4")}
                    </a>
                  </div>
                  <div>
                    <a onClick={launchPage} className="yellow-btn">
                      {t("button.5")}
                    </a>
                  </div>
                </section>
              </div>
              <div>
                <img src="/images/pk10/pk10.jpg" alt="" />
                <div className="pk10Logo">
                  <img src="/images/pk10/PK10-logo.png" alt="" />
                </div>
                <div>
                  <a onClick={launchPage} className="yellow-btn">
                    {t("button.1")}
                  </a>
                </div>
              </div>
            </div>
          )}
          <Popup
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            modal
            nested
          >
            <div
              className="popupError"
              style={{
                backgroundImage: `url('/images/registration-form-bg.jpg')`,
              }}
            >
              <img src="/images/promo-mobile-icon.webp" alt="Promo" />
              <div className="content">{popupMessage}</div>
              <div className="actions">
                <button
                  className="yellow-btn"
                  onClick={() => setIsPopupOpen(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </>
  );
}

export default Pk10Keno;
