import React from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const currentPath = window.location.pathname;
const currentDomain = window.location.origin;

export default function PrivacyAndPolicy() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Learn about SG8Bet's 2024 privacy policy. Understand how we protect your personal information and ensure a secure gaming environment. Read our policy now."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <section className="privacyPolicy">
        <div className="page-headlines-container">
          <div className="pageHeaderText">
            <h1>Privacy and Policy</h1>
          </div>
        </div>
        <p>
          {" "}
          Our Privacy and Cookie Notice below sets out in detail how we process
          your personal data, and we encourage you to read it in full.{" "}
        </p>
        <p> We collect and process your personal data for two main reasons: </p>
        <ol>
          <li>To provide you with services and </li>
          <li>To comply with legal and regulatory obligations. </li>
        </ol>
        <p>
          {" "}
          For instance, if you wish to enjoy the games and services provided on
          SG8, you will need to set up an account with us. This means that we
          will require your personal data such as your name, username, contact
          details, date of birth, etc. in order to create your account. We will
          also need to ensure that you are of legal playing age, henceforth we
          will process your personal data for identification and age
          verification checks as well.{" "}
        </p>
        <p>
          {" "}
          We wish to offer you the best playing experience possible, at the same
          time ensuring that you are gaming responsibly.{" "}
        </p>
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Introduction</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We are committed to protecting your privacy and personal data.
                This Privacy and Cookie Notice (the Notice) will inform you as
                to how we handle your personal data when you visit our website
                as well as to inform you on your privacy rights and how the law
                protects you. We reserve the right to modify this Notice as and
                when required, without consent. Any changes to our Notice will
                become effective upon posting of the revised Notice on the
                Website and we will notify you of any material changes to this
                Notice. We recommend that you review this Notice from
                time-to-time.
              </p>
              <p>
                {" "}
                This Notice should be read together with our general “Terms and
                Conditions”.{" "}
              </p>
              <p>
                {" "}
                It is crucial that the personal data you disclose is accurate
                and up-to-date. Please keep us informed and update your personal
                data if there are any changes during your relationship with us.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Collected Information</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Personal data, or personal information, means any information
                about an individual from which that person can be identified,
                excluding data whereby the identity has been removed (i.e.
                Anonymous data). We may collect, use, store and transfer
                different kinds of personal data about you which includes:
              </p>
              <ul>
                <li>
                  {" "}
                  Identity Data includes first name, last name, username or
                  similar identifier, marital status, title, date of birth and
                  gender.{" "}
                </li>
                <li>
                  {" "}
                  Contact Data includes billing address, email address and
                  telephone number as well as any other information provided at
                  the time of registering to use our Website, subscribing to any
                  of our services (including promotions about products), posting
                  material or requesting further services.{" "}
                </li>
                <li>
                  {" "}
                  Correspondence Data includes information you provide when
                  entering a promotion or competition, making a Customer Support
                  enquiry or complaint or when you report a problem with our
                  Website, any other records of correspondence if you contact us
                  about any matter. Your telephone conversations may be recorded
                  both to assist us in training and in quality management, and
                  to allow for the speedy resolution of queries.{" "}
                </li>
                <li>
                  {" "}
                  Financial Data includes bank account information and
                  information relating to your financial position.{" "}
                </li>
                <li>
                  {" "}
                  Transaction Data includes details about payments to and from
                  you and other details of products and services you have
                  purchased from us.{" "}
                </li>
                <li>
                  {" "}
                  Technical Data includes internet protocol (IP) address, your
                  login data, browser type and version, time zone setting and
                  location, browser plug-in types and versions, operating system
                  and platform and other technology on the devices you use to
                  access this website.{" "}
                </li>
                <li>
                  {" "}
                  Profile Data includes your username and password and wagering
                  completed by you.{" "}
                </li>
                <li>
                  {" "}
                  Usage Data includes information about how you use our website.{" "}
                </li>
                <li>
                  {" "}
                  Marketing and Communications Data includes your preferences in
                  receiving marketing from us and our third parties and your
                  communication preferences, as can be found in the My Account
                  section.{" "}
                </li>
                <li>
                  {" "}
                  We also collect, use and share Aggregated Data such as
                  statistical or demographic data for any purpose. Aggregated
                  Data may be derived from your personal data but is not
                  considered personal data in law as this data does not directly
                  or indirectly reveal your identity.{" "}
                </li>
                <li>
                  {" "}
                  We may also collect certain special categories of personal
                  data about you (such as details about your health) and
                  information about criminal convictions and offences. This
                  information is not collected by us in the ordinary course of
                  business but only in exceptional circumstances and shall only
                  be processed to comply with legal or regulatory obligations
                  and responsibilities or where you have provided this
                  information to us.{" "}
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Failure to Provide Personal Data
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Where we need to collect personal data by law, or under the
                terms of a contract we have with you and you fail to provide
                that data when requested, we may not be able to perform the
                contract we have or are trying to enter into with you (i.e. To
                extend our products and services to you via our website). In
                this case, we may have to cancel a product or service you have
                with us, or otherwise prevent you from using our product or
                services. We will notify you in the event such circumstances
                arise{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Personal Data Collection
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                SG8 may collect your personal data using different methods
                including:{" "}
              </p>
              <ul>
                <li>
                  Direct interactions – You may provide your personal data by
                  filling in forms or by corresponding with us. This includes
                  personal data you provide when you create an account on our
                  website; place bets, or wager funds, on a game, via our
                  website; request marketing to be sent to you; enter a
                  competition, promotion or survey; or provide feedback.
                </li>
                <li>
                  Automated technologies or interactions – As you interact with
                  our website, we may automatically collect data about your
                  equipment, browsing actions and patterns, which may include
                  personal data. We collect such personal data by using cookies,
                  server logs and other similar technologies. Please refer to
                  our cookie section below for more information.
                </li>
                <li>
                  Third parties or publicly available sources – We may receive
                  personal data about you from various third parties and public
                  sources to supplement personal data we hold about you for the
                  processing purposes of prevention or detection of crime, as
                  well as responsible gambling.
                </li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Personal Data Usage</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                In accordance with data protection laws, we will only process
                your personal data where we have a lawful basis for doing so.
                With respect to your personal data, these bases are: (i) where
                it is necessary to provide services to you under the performance
                of the contract we have with you; (ii) where we are required to
                do so in accordance with legal or regulatory obligations; (iii)
                where you have given your consent; and (iv) where it is in our
                legitimate interests to process your personal data provided that
                these interests do not prejudice your own rights, freedoms and
                interests.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Transactions Monitoring</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                All bets, gaming and account transactions are recorded on our
                back-end systems. We carry out routine monitoring of all
                transactions as they take place to assist us in improving our
                services provided to you, as well as to ensure compliance with
                our licensing and regulatory obligations.{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Security</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                {" "}
                We employ security measures to ensure the protection of your
                personal data from access by unauthorised persons, and the
                prevention of unlawful processing, accidental loss, destruction
                and damage. These safeguards vary based on the sensitivity of
                the information collected and stored. Protection of your
                personal data, including your registration details and any
                banking information you provide to us, is of utmost priority{" "}
              </p>
              <p>
                {" "}
                However, do note that the transmission of Information via the
                internet is not entirely secure. Although we will ensure our
                utmost effort in protecting your Information, we cannot
                guarantee the security of any personal data that you disclose
                online. You accept the inherent security implications of using
                the internet and will not hold us responsible for any breach of
                security unless we have failed in our obligations under data
                protection legislation. For your own privacy protection, we
                encourage you not to include any sensitive information on any
                online communication channels with us (i.e Emails, Live chats,
                etc.).{" "}
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Personal Data Retention</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We will hold your personal data for as long as you continue to
                use our products and services offered on SG8. We will not retain
                any personal data where it is not necessary for us to do so, but
                please be aware that we may retain personal data for extended
                durations given the circumstances whereby we are under a legal
                obligation to do so, or where we have reasonable belief that it
                is necessary to do so for business or legal reasons.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Third Party Collection of Information
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                This Notice does not apply to any third-party websites, services
                or applications, regardless of whether they are accessible,
                downloadable, or otherwise distributed through the Website. You
                should always review their privacy practices carefully before
                providing personal information to such said third parties.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                Disclosure of Information
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                There may be instances whereby we would be required by law,
                court order or governmental authority to disclose certain
                personal data. Such situations whereby this would occur includes
                the administration of justice, or where we have to defend
                ourselves legally.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </section>
    </>
  );
}
