import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { currentPath, currentDomain } from '../App';
import { useTranslation } from "react-i18next";
import { promotionList as originalPromotionList } from './promotionList';


function isFilePath(path) {
  // Regular expression to check for common file extensions
  const fileExtensionPattern = /[^\\/]+\.[^\\/]+$/;
  
  return fileExtensionPattern.test(path);
}

function Promotion() {
  const [visibleId, setVisibleId] = useState(null);
  const { t, i18n } = useTranslation();

  // Map through promotionList and update titles with translations
  const promotionList = originalPromotionList.map(promotion => ({
    ...promotion,
    title: t(promotion.titleKey),
    desc: t(promotion.desc),
    alt: t(promotion.alt),
    promoClick: t(promotion.promoClick),
    imgLarge: promotion.imageLrg ? t(promotion.imageLrg): promotion.imageLrg,
    imgSmall: isFilePath(promotion.imgSmall) ? t(promotion.imgSmall): promotion.imgSmall
  }));
  console.log(promotionList);
  // Sort promotions by priority, highest first
  const sortedPromotionList = [...promotionList].sort((a, b) => a.priority - b.priority);

  const listItems = sortedPromotionList.map((data) => (
    <li className={`promoBox ${data.classname || ""}`} key={data.id}>
      <img
        className="promotionBannerMobile"
        src={data.imgSmall}
        alt={data.alt}
      />
      <img className="promotionBanner" src={data.imgSmall} alt={data.alt} />
      <div className="promotionContent">
        <p className="promotion-title">{data.title}</p>
        <p className="promotion-info">{data.desc}</p>
        <div className="promolink-container">
        {console.log("is file? " + isFilePath(data.imgSmall))}
          <a className="yellow-btn" href={data.promoClick} target={data.target ? "_blank" : ""}>
            T&C
          </a>
        </div>
      </div>
    </li>
  ));

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
          SG8Bet
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Unlock amazing promotions at SG8Bet. Maximize your gaming potential and start winning today!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1>{t('titles.3')}</h1>
            </div>
          </div>
          <div className="promotionContentarea">
            <ul className="promotionWrapper">{listItems}</ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Promotion;
