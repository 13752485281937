import React, { useEffect, useState } from 'react';
import { newsList as translatednewsList } from './newsList';
import { useTranslation } from 'react-i18next';
import LogoutFunction from './LogoutFunction';

function LogoutButton() {
  const { t, i18n } = useTranslation();
  const [currentActive, setCurrentActive] = useState(0);
  const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false);
  const duration = 3000;

  const newsItems =  translatednewsList.map(news => ({
    ...news,
    title: t(news.content)
  }));

  const totalNews = newsItems.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentActive(prevActive => (prevActive + 1) % totalNews);
    }, duration);

    return () => clearInterval(intervalId); 
  }, [totalNews, duration]);

  useEffect(() => {
    setIsBodyLoggedIn(document.body.classList.contains('isLoggedIn'));
  }, []);

  return (
    <div className="header-news-logout">
      <div className="news-container">
        <div className="news-heading">News:</div>
        {newsItems.map((item, index) => (
          <div key={item.id}>
            <a href={t(item.link)} className={`news-single ${index === currentActive ? 'active' : ''}`}>{t(item.content)}</a>
          </div>
        ))}
      </div>
      {isBodyLoggedIn && (
        <span className="logout-BTN">
          <LogoutFunction />
        </span>
      )}
    </div>
  );
}

export default LogoutButton;
