import React from "react";
import { useTranslation } from 'react-i18next';
export default function SportsbookFooter() {
  const { t, i18n } = useTranslation();
  return <>
    <section className="sportsbook">
        <p>
          {t('sports.1')}
        </p>
        <ul>
          <li>
            <img src="/images/livedealer/nba.png" alt="" />
          </li>
          <li>
            <img src="/images/livedealer/mlb.png" alt="" />
          </li>
          <li>
            <img src="/images/livedealer/nfl.png" alt="" />
          </li>
          <li>
            <img src="/images/livedealer/nhl.png" alt="" />
          </li>
          <li>
            <img src="/images/livedealer/LaLiga.png" alt="" />
          </li>
          <li>
            <img src="/images/livedealer/premier.png" alt="" />
          </li>
        </ul>
      </section>
  </>;
}
