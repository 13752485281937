import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { currentPath, currentDomain } from "../App";
import SportsbookFooter from "../snippets/SportsbookFooter";
import { launchGame, AlertModal } from "./LiveCasino";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function Sports() {
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const imageRef = useRef(null);
  const totalFrames = 77;
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );
  const [isMobile, setIsMobile] = useState(
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  );
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 15);
    }
    return () => clearInterval(interval);
  }, [launching]);
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Bet on your favorite sports with SG8Bet's premier sportsbook. Enjoy competitive odds and a seamless betting experience."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      {!isMobile ? ( //desktop
        <div className="main-wrapper">
          <div className="main-content-area">
            <div className="page-headlines-container">
              <div className="pageHeaderText">
                <h1 className="sportsTitle">{t("sports.2")}</h1>
              </div>
            </div>
            <div className="liveCasino sports">
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/cmd-logo.png"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/cmd.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a href="/sportsbook/cmd368/" className="yellow-btn">
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/sbobet-logo.webp"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/sbobet.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a href="/sportsbook/sbobet/" className="yellow-btn">
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/ap-gaming.svg"
                    alt=""
                  />

                  <img
                    className="charMain"
                    src="/images/livedealer/pinnacle.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a href="/sportsbook/ap-sports/" className="yellow-btn">
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : //isMobile
      isLoggedIn ? (
        <div className="main-wrapper">
          <div className="main-content-area">
            <div className="page-headlines-container">
              <div className="pageHeaderText">
                <h1 className="sportsTitle">{t("sports.2")}</h1>
              </div>
            </div>
            <div className="liveCasino sports">
              {launching && isLoggedIn && (
                <div className=" overlayStyle">
                  <div className="loadingBar">
                    <div
                      className={`spinner ${animationComplete ? "pop" : ""}`}
                    >
                      <img
                        ref={imageRef}
                        src={`/images/frameLoading/sg8logo_${String(
                          currentFrame
                        ).padStart(5, "0")}.png`}
                        alt="Loading..."
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/cmd-logo.png"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/cmd.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      onClick={() =>
                        launchGame(
                          "",
                          "45001",
                          true,
                          t("lang.1"),
                          0,
                          true,
                          setLaunching,
                          setCurrentFrame,
                          setAnimationComplete,
                          t
                        )
                      }
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/sbobet-logo.webp"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/sbobet.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      onClick={() =>
                        launchGame(
                          "",
                          "70006",
                          true,
                          t("lang.1"),
                          0,
                          true,
                          setLaunching,
                          setCurrentFrame,
                          setAnimationComplete,
                          t
                        )
                      }
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/ap-gaming.svg"
                    alt=""
                  />

                  <img
                    className="charMain"
                    src="/images/livedealer/pinnacle.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      onClick={() =>
                        launchGame(
                          "",
                          "62005",
                          true,
                          t("lang.1"),
                          0,
                          true,
                          setLaunching,
                          setCurrentFrame,
                          setAnimationComplete,
                          t
                        )
                      }
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        //Logged out mobile
        <div className="main-wrapper">
          <div className="main-content-area">
            <div className="page-headlines-container">
              <div className="pageHeaderText">
                <h1 className="sportsTitle">{t("sports.2")}</h1>
              </div>
            </div>
            <div className="liveCasino sports">
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/cmd-logo.png"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/cmd.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      href="https://www.sg8game.com/wlp/demo/sports/cmd.jsp"
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/sbobet-logo.webp"
                    alt=""
                  />
                  <img
                    className="charMain"
                    src="/images/livedealer/sbobet.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      href="https://www.sg8game.com/wlp/demo/sports/sbo.jsp?l=th&m=1"
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
              <div className="liveCasinoContent">
                <div className="liveContainer">
                  <img
                    className="liveCasinoLogo"
                    src="/images/livedealer/ap-gaming.svg"
                    alt=""
                  />

                  <img
                    className="charMain"
                    src="/images/livedealer/pinnacle.jpg"
                    alt=""
                  />

                  <div className="liveCasinoBtn sportsbtn">
                    <a
                      href="https://hszbtad.tender88.com/en/compact/sports/soccer"
                      className="yellow-btn"
                    >
                      {t("button.1")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SportsbookFooter />
    </>
  );
}

export default Sports;
