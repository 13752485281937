
import { useTranslation } from 'react-i18next';
function AccountSelection() {
  const { t, i18n } = useTranslation();
  const currentPath = window.location.pathname;
  return (
    <div className="account-navigation">
      <div className="fix-container"> 
        <a className={`item-request ${currentPath === '/account/request-report/' ? 'active' : ''}`} href="/account/request-report/">{t('report.tab.1')}</a>
        <a className={`item-game ${currentPath === '/account/game-report/' ? 'active' : ''}`} href="/account/game-report/">{t('report.tab.2')}</a>
        <a className={`item-funds ${currentPath === '/account/funds-report/' ? 'active' : ''}`} href="/account/funds-report/">{t('report.tab.3')}</a>
        <a className={`item-referral ${currentPath === '/account/referral/' ? 'active' : ''}`} href="/account/referral/">{t('report.tab.4')}</a>
      </div>
    </div>
  );
}

export default AccountSelection;