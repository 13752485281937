export const newsList = [
    { 
    id: 1, 
    content: 'news.1.content',
    link: 'news.1.link'
    },
    {
    id: 2, 
    content: 'news.2.content',
    link: 'news.2.link'
    },
    { 
    id: 3, 
    content: 'news.3.content',
    link: 'news.3.link' 
    },
]