import React from "react";
import { useTranslation } from "react-i18next";
export default function Back() {
  const { t, i18n } = useTranslation();
  const handleClick = (e) => {
    e.preventDefault();
    window.history.go(-1);
  };
  return <>
  <div className="sportsBtn">
    <a onClick={handleClick} className="yellow-btn">{t('home.5')}</a>
  </div>
  </>;
}
