import React, { useState, useEffect, useCallback } from 'react';
import { apiJSONURL } from './LiveCasino';
import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection";
import { useTranslation } from 'react-i18next';
const GameReport = () => {
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    userName: "...",
    turnover: "--",
    winloss: "--",
    fromDate: "",
    toDate: "",
    isFetching: false,
    reportDetails: [],
    showDetails: false,
    displaySummary: false,
  });

  useEffect(() => {
    const currentDate = getCurrentDate();
    setState(prevState => ({
      ...prevState,
      fromDate: currentDate,
      toDate: currentDate,
      userName: localStorage.getItem('username') || prevState.userName
    }));
  }, []);

  const getCurrentDate = () => {
    const d = new Date();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${d.getFullYear()}-${month}-${day}`;
  };

  const handleSearch = useCallback(async () => {
    resetData();
    setState(prevState => ({ ...prevState, isFetching: true }));

    try {
      const storedUserName = localStorage.getItem('username');
      if (!storedUserName) {
        throw new Error("Username not found in localStorage");
      }

      const token = await fetchToken(storedUserName);
      const decodedToken = decodeURIComponent(token);

      const reportData = await fetchReportData(decodedToken);
      if (reportData && Object.keys(reportData).length > 0) {
        const [, turnoverValue, winlossValue] = reportData[Object.keys(reportData)[0]];
        setState(prevState => ({
          ...prevState,
          turnover: turnoverValue || "0.00",
          winloss: winlossValue || "0.00"
        }));

        await fetchReportDetails(decodedToken);
      } else {
        // If reportData is empty or invalid, set turnover and winloss to "0.00"
        setState(prevState => ({
          ...prevState,
          turnover: "0.00",
          winloss: "0.00"
        }));
      }
    } catch (error) {
      console.error("Error fetching report data:", error);
    } finally {
      setState(prevState => ({ ...prevState, isFetching: false }));
    }
  }, [state.fromDate, state.toDate]);

  const resetData = () => {
    setState(prevState => ({
      ...prevState,
      turnover: "--",
      winloss: "--",
      reportDetails: [],
      showDetails: false,
      displaySummary: false // Add this line
    }));
  };

  const fetchToken = async (userName) => {
    const tokenFormData = new URLSearchParams({ cmd: "getToken", u: userName });
    const tokenResponse = await fetch(apiJSONURL, { method: "POST", body: tokenFormData });
    const tokenData = await tokenResponse.json();
    return tokenData.token;
  };

  const fetchReportData = async (decodedToken) => {
    const formData = new URLSearchParams({
      fd: state.fromDate,
      td: state.toDate,
      cmd: "getGamingReport",
      u: decodedToken
    });
    const reportResponse = await fetch(apiJSONURL, { method: "POST", body: formData });
    return await reportResponse.json();
  };

  const fetchReportDetails = async (decodedToken) => {
    const formData = new URLSearchParams({
      fd: state.fromDate,
      td: state.toDate,
      cmd: "getGamingReportDetails",
      u: decodedToken
    });
    const reportDetailsResponse = await fetch(apiJSONURL, { method: "POST", body: formData });
    const reportDetailsData = await reportDetailsResponse.json();

    if (reportDetailsData && Object.keys(reportDetailsData).length > 0) {
      const details = Object.values(reportDetailsData)[0].map(detail => {
        const [provider, turnover, winloss] = detail.split("#");
        return { provider, turnover, winloss };
      });
      setState(prevState => ({
        ...prevState,
        reportDetails: details
      }));
    } else {
      throw new Error("Empty or invalid report details data received");
    }
  };

  const getProviderName = (providerCode) => {
    const providerMap = {
      "RNG-RTG": "RTG",
      "RNG-SPADE": "SpadeGaming",
      "RNG-SPADEFISH": "SpadeGaming (Fishing)",
      "RNG-ICG": "AstroTech",
      "RNG-ICGFISH": "AstroTech (Fishing)",
      "RNG-BETSOFT2": "Betsoft",
      "RNG-BOOONGO": "BNG",
      "RNG-SG8-BO": "BNG",
      "RNG-CQ9": "CQ9",
      "RNG-CQ9FISH": "CQ9 (Fishing)",
      "RNG-DRAGON": "Dragon Gaming",
      "RNG-GAMATRON": "Gamatron",
      "RNG-GIO": "GiocoPlus",
      "RNG-JILI": "JiLi",
      "RNG-NETENT": "NetEnt",
      "RNG-PLAYSON": "Playson",
      "RNG-SG8-PL": "Playson",
      "RNG-REDTIGER (EVO)": "Red Tiger",
      "RNG-YGG": "Yggdrasil",
      "RNG-INBET": "InBet",
      "RNG-NEXTSPIN": "Nextspin",
      "SB-PIN": "Pinnacle",
      "LD-EVO": "Evolution",
      "RNG-FACHAI": "Fachai",
      "RNG-PS": "PlayStar"
    };
    return providerMap[providerCode] || providerCode;
  };

  return (
    <div className="account-content report-page">
      <AccountSelection />
      <div className="welcome-user">
        <button id="verifyAccountPUM" className="popmake-4154 pum-trigger">Verify</button>
        <button id="uploadPhotoPUM" className="popmake-4137 pum-trigger">Upload Photo</button>
      </div>
      <div className="account-container tier-info">
        <div className="table-fields">
        <div className="report-info">
          <h2>{t('report.title.2')}</h2>
            <div className="title-line"></div>
              <div>
                  <p className="referral-text">{t('report.desc.2')}</p>
              </div>
            </div>
          <form id="gr-requestForm">
            <div className="from-to-type-container">
              <div className="field-row">
                <label>{t('label.16')}:</label>
                <input type="date" name="fd" value={state.fromDate} onChange={(e) => setState(prevState => ({ ...prevState, fromDate: e.target.value }))} />
              </div>
              <div className="field-row">
                <label>{t('label.17')}:</label>
                <input type="date" name="td" value={state.toDate} onChange={(e) => setState(prevState => ({ ...prevState, toDate: e.target.value }))} />
              </div>
              <div className="field-row">
                <button id="gr-searchBtn" className="yellow-btn" type="button" onClick={handleSearch} disabled={state.isFetching}>
                  <i className="fa fa-search"></i>{t('header.5')}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="display-flex" style={{ marginTop: "40px" }}>
          <div className="summary-amount">
            <div className="amount" id="gr-turnover">{state.turnover}</div>
            <div className="amount-label">{t('label.19')}</div>
          </div>
          <div className="summary-amount">
            <div className="amount" id="gr-winloss">{state.winloss}</div>
            <div className="amount-label">{t('label.20')}</div>
          </div>
          <div className="summary-amount" id="gr-details">
            <div className="report-detail" onClick={() => setState(prevState => ({ ...prevState, showDetails: !prevState.showDetails, displaySummary: !prevState.displaySummary }))}>{t('label.22')}</div>
          </div>
        </div>
        {state.showDetails && (
          <div className="summary" id="gr-summary" style={{ marginTop: "15px", display: state.displaySummary ? 'block' : 'none' }}>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>{t('label.21')}</th>
                    <th>{t('label.19')}</th>
                    <th>{t('label.20')}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.reportDetails.length > 0 ? (
                    state.reportDetails.map((detail, index) => (
                      <tr key={index} className="reportResult2">
                        <td>{getProviderName(detail.provider)}</td>
                        <td>{detail.turnover}</td>
                        <td>{detail.winloss}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>{t('msg.10')}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameReport;
