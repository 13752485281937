import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import { BlogList } from "./../Blog";
import { apk } from "../../../Footer";
import {currentPath, currentDomain} from "../../../App";

function LatestPromotion() {
  const location = useLocation();
  const latestPromotion = BlogList.find((blog) => blog.link === currentPath);
  const [image, setImage] = useState(latestPromotion?.images.desktop);

  const handleLinkClick = () => {
    window.location.href = apk;
  };
  const currentPage = window.location.pathname;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setImage(latestPromotion?.images.mobile);
      } else {
        setImage(latestPromotion?.images.desktop);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [latestPromotion]);

  if (!latestPromotion) {
    return <div>No promotion found.</div>;
  }

  const getPageName = (path) => {
    const page = BlogList.find((blog) => blog.link === path);
    return page ? page.title : "";
  };

  const parentPageLink = "/news/"; // Update this if the parent page link is different
  const parentPageName = getPageName(parentPageLink) || "News";

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Experience Casino Filipino at Its Best On Mobile App
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="10x Faster Performance Enjoy a lightning-fast gaming
          experience with our mobile app."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="blog-navigation">
            <Link to={parentPageLink}>{parentPageName}</Link>
            <Link to={currentPath} className="active">
              {latestPromotion.title}
            </Link>
          </div>
          <div className="page-headlines-container">
            <div className="blog-content">
              <img
                src={image}
                className="hero-images-blog"
                alt={latestPromotion.title}
              />
              <div className="pageHeaderText">
                <h1>{latestPromotion.title}</h1>
                <p>{latestPromotion.content}</p>
                <div className="app-button">
                  <a
                      onClick={() => handleLinkClick(apk)} style={{cursor: "pointer"}}
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/download/download.png"
                        title="Download App"
                        alt="Download our App now!"
                      />
                    </a>
                  </div>
              </div>
              <div className="blog-text-content">
                <div className="blog-row">
                  <h2 className="h2-text">
                    Unlock the Full Experience Download Our SG8 Casino Mobile
                    App Now!
                  </h2>
                  <p className="blog-p-tag-headline">
                    Experience Casino Filipino at Its Best
                  </p>
                  <p className="blog-p-tag">
                    Are you ready to elevate your gaming experience? SG8 Casino
                    is excited to introduce our state-of-the-art mobile app,
                    designed to bring the thrill of Casino Filipino right to
                    your fingertips. Whether you're at home or on the go, our
                    app ensures you never miss a moment of action.
                  </p>
                </div>
                <div className="blog-row">
                  <h2 className="h2-text">
                    Why Choose the SG8 Casino Mobile App?
                  </h2>
                  <ul className="ul-blog">
                    <li>
                      10x Faster Performance Enjoy a lightning-fast gaming
                      experience with our mobile app. Say goodbye to lag and
                      hello to seamless gameplay.
                    </li>
                    <li>
                      More Seamless Integration: Our app is designed to provide
                      a more seamless connection to your favorite games,
                      ensuring you stay in the zone.
                    </li>
                    <li>
                      Easy to Navigate: With a user-friendly interface, our app
                      makes it easy to find and play your favorite games. Spend
                      less time searching and more time winning.
                    </li>
                    <li>
                      Professional UI/UX Design: Our professional UI/UX design
                      ensures a visually appealing and intuitive experience,
                      making every session enjoyable and effortless.
                    </li>
                    <li>
                      More Games: Access a wider variety of games than ever
                      before. From classic casino games to the latest slots, our
                      app has something for everyone.
                    </li>
                  </ul>
                </div>
                <div className="blog-row">
                  <h2 className="h2-text">Our Top Provider JILI Games</h2>
                  <p className="blog-p-tag">
                    At SG8 Casino, we are proud to feature games from leading
                    providers, including JILI Games. Renowned for their
                    innovative and exciting titles, JILI Games brings a new
                    level of excitement to your gaming experience. Here are
                    three standout games you can enjoy:
                  </p>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/super-ace-jackpot.webp"
                      alt="super ace by jili games at sg8"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">Super Ace by JILI Games</h2>
                    <p className="blog-p-tag">
                      Test your skills in this thrilling card game. Super Ace
                      combines strategy and excitement, offering a unique and
                      engaging gameplay experience.
                    </p>
                    <a href="/slots/jili/">
                      <button className="blog-btn">JILI Games</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/Fortune-Gems-Super-Win.webp"
                      alt="super ace by jili games at sg8"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">Fortune Gems by JILI Games</h2>
                    <p className="blog-p-tag">
                      Spin the reels and watch as the gems align to bring you
                      fantastic rewards. Fortune Gems combines classic slot
                      mechanics with modern features for a captivating
                      experience.
                    </p>
                    <a href="/slots/jili/">
                      <button className="blog-btn">JILI Games</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/Golden-Empire-Jackpot.webp"
                      alt="super ace by jili games at sg8"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">Golden Empire by JILI Games</h2>
                    <p className="blog-p-tag">
                      Explore the riches of the ancient empire in this thrilling
                      slot game. With multiple paylines and bonus features,
                      Golden Empire offers endless opportunities for big wins.
                    </p>
                    <a href="/slots/jili/">
                      <button className="blog-btn">JILI Games</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row">
                  <h2 className="h2-text">
                    Exciting Promotions to Boost Your Winnings
                  </h2>
                  <p className="blog-p-tag">
                    To make your gaming experience even more rewarding, SG8
                    Casino offers a range of exciting promotions:
                  </p>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/cashback-at-sg8-mobile.webp"
                      alt="cashback bonus at sg8"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">CashBack Up to 6%</h2>
                    <p className="blog-p-tag">
                      Never worry about a losing streak again. With our CashBack
                      promotion, you can earn up to 6% cash back on your losses,
                      giving you more chances to win.
                    </p>
                    <a href="/promotion/cash-back-bonus/">
                      <button className="blog-btn">Learn More</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/promotion/deposit-bonus-at-sg8-mobile.webp"
                      alt="150% deposit bonus"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">
                      150% Deposit Bonus on First Deposit
                    </h2>
                    <p className="blog-p-tag">
                      Your First Deposit: Kickstart your SG8 Casino journey with
                      a 150% bonus on your first deposit. This substantial bonus
                      gives you extra funds to explore our vast selection of
                      games.
                    </p>
                    <a href="/promotion/deposit-bonus/">
                      <button className="blog-btn">Learn More</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row blog-row-flex">
                  <div className="blog-img">
                    <img
                      src="/images/refer-a-friend-at-sg8-mobile.webp"
                      alt="bring your friend to play"
                    />
                  </div>
                  <div className="blog-row-content">
                    <h2 className="h2-text">
                      Friend Referral Bonus – Up to 10%
                    </h2>
                    <p className="blog-p-tag">
                      Share the excitement with your friends and earn rewards.
                      For every friend you refer who makes a deposit, you can
                      earn a referral bonus of up to 10%.
                    </p>
                    <a href="/promotion/referral-bonus/">
                      <button className="blog-btn">Learn More</button>
                    </a>
                  </div>
                </div>
                <div className="blog-row">
                  <h2 className="h2-text">Download and Register Now!</h2>
                  <p className="blog-p-tag">
                    Don't miss out on the full SG8 Casino experience. Download
                    our mobile app today and unlock a world of excitement and
                    rewards. Register now to start enjoying faster performance,
                    seamless integration, and exclusive promotions.
                  </p>
                  <div className="app-button">
                    <a
                      onClick={() => handleLinkClick(apk)}
                      style={{ cursor: "pointer" }}
                      target="_new"
                      rel="noopener"
                    >
                      <img
                        src="/images/download/download.png"
                        title="Download App"
                        alt="Download our App now!"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestPromotion;
