import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import { BlogList } from './../Blog';
import {currentPath, currentDomain} from "../../../App";

function LatestPromotion() {
    const location = useLocation();
    const latestPromotion = BlogList.find(blog => blog.link === currentPath);
    const [image, setImage] = useState(latestPromotion?.images.desktop);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setImage(latestPromotion?.images.mobile);
            } else {
                setImage(latestPromotion?.images.desktop);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, [latestPromotion]);

    if (!latestPromotion) {
        return <div>No promotion found.</div>;
    }

    const getPageName = (path) => {
        const page = BlogList.find(blog => blog.link === path);
        return page ? page.title : "";
    };

    const parentPageLink = "/news/"; // Update this if the parent page link is different
    const parentPageName = getPageName(parentPageLink) || "News";

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Exciting Promotions at SG8 Casino - Boost Your Gameplay and Winnings</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta
                    name="description"
                    content="Enjoy up to 6% cashback, a 150% first deposit bonus, friend referral bonuses, payday bonuses, and a special birthday bonus of 500 PHP."
                />
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <div className="blog-navigation">
                        <Link to={parentPageLink}>{parentPageName}</Link><Link to={currentPath} className="active">{latestPromotion.title}</Link>
                    </div>
                    <div className="page-headlines-container">
                        <div className="blog-content">
                            <img src={image} className="hero-images-blog" alt={latestPromotion.title} />
                            <div className="pageHeaderText">
                                <h1>{latestPromotion.title}</h1>
                                <p>{latestPromotion.content}</p>
                            </div>
                            <div className="blog-text-content">
                                <div className="blog-row">
                                    <h2 className="h2-text">Discover the Exciting Promotions at SG8 Casino!</h2>
                                    <p className="blog-p-tag">At SG8 Casino, we're dedicated to providing our players with the best gaming experience possible. To make your time with us even more rewarding, we've rolled out a series of amazing promotions designed to boost your gameplay and increase your winnings. Whether you're a seasoned player or new to the world of online gaming, our promotions cater to all levels and preferences.</p>
                                    <p className="blog-p-tag">Our promotions are crafted to give you more value for your time and money. From generous cashback offers that help you recover from losses to substantial deposit bonuses that give you extra funds to play with, every promotion is aimed at enhancing your overall experience. We believe that every player deserves to feel like a winner, and our promotions are a testament to that commitment.</p>
                                    <p className="blog-p-tag">By joining SG8 Casino, you also gain access to a variety of exclusive bonuses that can significantly improve your chances of hitting it big. We continuously update our promotions to ensure that there's always something new and exciting for you to take advantage of. So, keep an eye on our promotions page and make the most of these fantastic opportunities to maximize your winnings and enjoyment.</p>
                                    <p className="blog-p-tag-headline">Here are our exciting promotions waiting for you:</p>
                                </div>
                                <div className="blog-row blog-row-flex">
                                    <div className="blog-img">
                                        <img src="/images/cashback-at-sg8-mobile.webp" alt="cashback bonus at sg8"/>
                                    </div>
                                    <div className="blog-row-content">
                                        <h2 className="h2-text">CashBack Up to 6%</h2>
                                        <p className="blog-p-tag">We know that sometimes luck doesn’t always go your way, and that’s why we’re offering a generous CashBack of up to 6%. Whether you’ve had a streak of bad luck or just want a little something extra, our CashBack promotion ensures you get a percentage of your losses back, giving you more chances to win!</p>
                                        <a href="/promotion/cash-back-bonus/"><button className="blog-btn">Learn More</button></a>
                                    </div>
                                </div>
                                <div className="blog-row blog-row-flex">
                                    <div className="blog-img">
                                        <img src="/images/promotion/deposit-bonus-at-sg8-mobile.webp" alt="150% deposit bonus"/>
                                    </div>
                                    <div className="blog-row-content">
                                        <h2 className="h2-text">150% Deposit Bonus on First Deposit</h2>
                                        <p className="blog-p-tag">New to SG8 Casino? Start your journey with a bang! Get a whopping 150% Deposit Bonus on your first deposit. This means if you deposit 100 PHP, you’ll receive an additional 150 PHP to play with. It’s the perfect way to explore our wide range of games and potentially score some big wins.</p>
                                        <a href="/promotion/deposit-bonus/"><button className="blog-btn">Learn More</button></a>
                                    </div>
                                </div>
                                <div className="blog-row blog-row-flex">
                                    <div className="blog-img">
                                        <img src="/images/refer-a-friend-at-sg8-mobile.webp" alt="bring your friend to play"/>
                                    </div>
                                    <div className="blog-row-content">
                                        <h2 className="h2-text">Friend Referral Bonus – Up to 10%</h2>
                                        <p className="blog-p-tag">Sharing is caring, and we want to reward you for bringing your friends to SG8 Casino. For every friend you refer, you can earn up to a 10% bonus on their deposits. The more friends you invite, the more you earn. It's a win-win for everyone!</p>
                                        <a href="/promotion/referral-bonus/"><button className="blog-btn">Learn More</button></a>
                                    </div>
                                </div>
                                <div className="blog-row blog-row-flex">
                                    <div className="blog-img">
                                        <img src="/images/promotion/payday-bonus-at-sg8-mobile.webp" alt="payday win big"/>
                                    </div>
                                    <div className="blog-row-content">
                                        <h2 className="h2-text">Payday Bonus</h2>
                                        <p className="blog-p-tag">Every payday should be special, and at SG8 Casino, we make sure it is! Enjoy exclusive Payday Bonuses that give you extra funds to play with. Check out our promotions page to see how you can claim your Payday Bonus this month.</p>
                                        <a href="/promotion/payday-bonus/"><button className="blog-btn">Learn More</button></a>
                                    </div>
                                </div>
                                <div className="blog-row blog-row-flex">
                                    <div className="blog-img">
                                        <img src="/images/sg8-birthday-bonus-special-mobile.webp" alt="birthday bonus enjoy our 500 php treat"/>
                                    </div>
                                    <div className="blog-row-content">
                                        <h2 className="h2-text">Birthday Bonus – Claim 500 PHP</h2>
                                        <p className="blog-p-tag">We love celebrating your special day! On your birthday, you can claim a 500 PHP bonus from SG8 Casino. It’s our way of saying thank you for being a valued player. Enjoy your birthday with some extra cash to play your favorite games.</p>
                                        <a href="/promotion/birthday-bonus/"><button className="blog-btn">Learn More</button></a>
                                    </div>
                                </div>
                                <div className="blog-row">
                                    <h2 className="h2-text">Top-Notch Games at SG8 Casino</h2>
                                    <p className="blog-p-tag">Our promotions aren’t the only thing that makes SG8 Casino stand out. We offer a vast selection of games to cater to every type of player.</p>
                                    <p className="blog-p-tag-headline">Online Slots</p>
                                    <p className="blog-p-tag">Dive into our exciting world of Online Slots, featuring popular titles and thrilling new releases. With games from top providers like <a href="/slots/jili/">JILI Games</a> and <a href="/slots/rtg/">RTG Slot</a>, you’re sure to find something that suits your style.</p>
                                    <p className="blog-p-tag-headline">Live Casino</p>
                                    <p className="blog-p-tag">Experience the thrill of a real casino from the comfort of your home with our <a href="/live-casino/">Live Casino</a> games. Play with live dealers and interact with other players in real-time. Evolution Games brings you the best in live gaming action.</p>
                                    <p className="blog-p-tag-headline">Sports Betting</p>
                                    <p className="blog-p-tag">For sports enthusiasts, our <a href="/sportsbook/">Sportsbook</a> offers a comprehensive range of betting options on various sports. Whether it’s football, basketball, or tennis, you can bet on your favorite teams and players and win big.</p>
                                </div>
                                <div className="blog-row">
                                <h2>Play with the Best Providers</h2>
                                    <p className="blog-p-tag">At SG8 Casino, we partner with leading game providers to ensure you have access to the highest quality games. Enjoy seamless gameplay, stunning graphics, and fair outcomes with games from:</p>
                                    <ul className="ul-blog">
                                        <li>JILI Games: Known for their innovative slots and engaging gameplay.</li>
                                        <li>RTG Slot: Renowned for their variety of themes and big win potential.</li>
                                        <li>Evolution Games: The leaders in live casino gaming, offering an immersive and interactive experience.</li>
                                    </ul>
                                    <p className="blog-p-tag">Join SG8 Casino today and take advantage of these fantastic promotions. Whether you’re a new player or a seasoned pro, there’s something for everyone. Don’t miss out on the chance to boost your gameplay and win more!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LatestPromotion;
