import React from "react";
import { Helmet } from "react-helmet";
import Pk10KenoLaunch from "../../snippets/Pk10KenoLaunch";
import {currentPath, currentDomain} from '../../App';
import Back from "../sportsbook/Back";
import {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PK10() {
  let newWindow;
  const gameId = "15521";
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn'));
  const [isMobile, setIsMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [gameWindow, setGameWindow] = useState(null);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus !== 'true' || isMobile===true) {
      navigate('/pk10-keno');
    }
  }, [navigate]);
  
  const openGameInNewWindow = () => {
    // const launchURL = document.querySelector(".gameIframe")?.src;
    // if (launchURL) {
    //   const newWindow = window.open(
    //     launchURL,
    //     "GameWindow",
    //     "width=800,height=600,resizable=yes,scrollbars=yes,status=yes"
    //   );
    //   setGameWindow(newWindow);
    //   setIsButtonClicked(true);
    // }
    const launchURL = document.querySelector(".gameIframe")?.src;
    newWindow = window.open(launchURL, "popup", "width=1024,height=768");
    setGameWindow(newWindow);
    setIsButtonClicked(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (gameWindow && gameWindow.closed) {
        setIsButtonClicked(false);
        setGameWindow(null);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [gameWindow]);
  
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Bet on your favorite sports with SG8Bet's premier sportsbook, powered by Pinnacle. Enjoy competitive odds and a seamless betting experience."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1 id="pinnaclesports">PK10</h1>
              <Back/>
              <div className="desktop-wrapper">
                {isLoggedIn ? (<button onClick={openGameInNewWindow}>
                  {t('label.15')}
            </button>): null}
              </div>
            </div>
          </div>
          <div className="sportsbook-game-selector desktop-wrapper">
            {!isButtonClicked && (
              <Pk10KenoLaunch gameId={gameId} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PK10;
