import React from "react";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

const currentPath = window.location.pathname;
const currentDomain = window.location.origin;


export default function Faq() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Find answers to your questions about SG8Bet in our 2024 FAQ section. Get detailed information on games, bonuses, account management, and more. Explore now!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <section className="faq">
        <div className="page-headlines-container">
          <div className="pageHeaderText">
            <h1>Frequently Asked Questions</h1>
            <p>Find answers to your questions about SG8 Casino?</p>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>GENERAL</Tab>
            <Tab>WALLET</Tab>
          </TabList>

          <TabPanel>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Can anyone register?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    No. Only individuals that fit the following criteria are
                    eligible to register:
                  </p>
                  <ul>
                    <li>21 years old and above</li>
                    <li>
                      Not a government official or employee, student,
                      unregistered player in an outlet or banned individuals as
                      listed in PAGCOR’s National Database for Restricted
                      Persons (NDRP).
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>How to join?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Referred by Existing Player – Register using the referral
                    link/code provided
                  </p>
                  <p>
                    {" "}
                    No Referrer – Click on the registration button located at
                    the top of the page to create an account
                  </p>
                  <p>
                    {" "}
                    Once the Registration pop-up appears, please fill in the
                    following fields :
                  </p>
                  <ul>
                    <li>
                      Full Name – Please provide your full name as stated in
                      your ID
                    </li>
                    <li>
                      Date of Birth – Please enter your date of birth as stated
                      in your ID
                    </li>
                    <li>
                      Mobile Number – Please provide a valid contact number
                    </li>
                    <li>
                      Email Address – Please provide a valid email address
                    </li>
                    <li>
                      Password – Password must be between 6-8 characters. Keep
                      your password confidential at all times.
                    </li>
                    <li>
                      Confirm Password – Re-enter your password to confirm.
                    </li>
                    <li>
                      Once completed, add us on Whatsapp, Viber or Telegram at
                      +63 927 412 3603
                    </li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How to Change Password?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    To change your password, please proceed to the “Change
                    Password” section located under “Dashboard”. The “Dashboard”
                    can be accessed from the side menu.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Forget password?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    If you have forgotten your password, click on “Forgot
                    Password or Username?” located within the “Login” pop-up.
                    You will be prompted to enter your registered mobile number
                    and an OTP will be sent to you for verification. Upon
                    successful verification, you will be prompted to enter your
                    new password.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    Is my personal information secure?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Yes. SG8 will not disclose any personal information to any
                    third parties. In addition, we utilise one of the best
                    security measures – SHA384 (Secure Hash Algorithm), a
                    cryptographic hash function designed by the National
                    Security Agency (NSA). You can rest assured that your
                    personal information remains confidential and secured at all
                    times.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Are the games fair?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    SG8 upholds fair gaming to the highest standards, providing
                    our players with only games that are fair, RNG certified and
                    compliant with PAGCOR’s rules and regulations. This creates
                    a safe and regulated platform for our players to play with
                    peace of mind.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What is RNG Certification?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    RNG certificates verify that online gaming platforms adhere
                    to RNG standards and have been approved by a certified
                    testing body. By complying with these standards, the outcome
                    of a card, game or other output is guaranteed to be
                    statistically random and unpredictable. In addition to
                    ensuring just and honest gameplay, this shows the value of a
                    trusted gaming ecosystem. As such, SG8 takes effort to
                    ensure that all games are RNG certified.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    How to turn off pop-up blocker?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>For Google Chrome users, please follow these steps: </p>
                  <ul>
                    <li>Click the Chrome Menu on the browser toolbar.</li>
                    <li>Select settings.</li>
                    <li>Click show advanced settings.</li>
                    <li>
                      In the “privacy section, click the content settings
                      button.
                    </li>
                    <li>
                      In the “Pop-ups” section, select “Allow all sites to show
                      pop-ups.” or Customize permissions for specific websites
                      by clicking manage exceptions and by add our website
                      “SG8.casino”.
                    </li>
                  </ul>
                  <p>For Apple users, follow these steps: </p>
                  <ul>
                    <li>Go to settings</li>
                    <li>Locate and click on “Safari”</li>
                    <li>Turn off “Block Pop-ups” function</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What is the difference between balance and credits (in slot
                    games)?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Balance is the cash amount available in your SG8 account. On
                    the other hand, credits are a form of in-game currency used
                    for wagering. Every unit of credit holds a specific cash
                    value (I.e., 1 Credit = PHP 10).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    What are the supported currencies?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    SG8 Is a gaming site operating in the Philippines, hence PHP
                    (Philippine Peso) will be used for all transactions.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
          <TabPanel>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    HOW DO I MAKE A DEPOSIT?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Login to your SG8 account and click on “Dashboard” in the
                    side menu. Under “”Dashboard””, click on “Wallet” located on
                    the left of the page. Follow all the steps as shown upon
                    choosing your deposit method of choice (Internet Banking or
                    Help2Pay) and fill in the necessary details of your
                    transaction. Once done, click “Submit” to proceed with your
                    Deposit.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    WHAT ARE THE WAYS TO DEPOSIT?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    We offer two methods to deposit on SG8: G-Cash and Internet
                    Banking (Help2Pay).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    DO I NEED TO PROVIDE ANY PROOF OF TRANSACTIONS FOR MY
                    DEPOSIT?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Yes. Proof of transaction is mandatory for verification in
                    order to successfully complete any deposit transactions.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    HOW LONG WILL IT TAKE FOR THE CREDITS TO BE ACCOUNTED IN MY
                    SYSTEM?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Your SG8 Balance will be updated within 3 – 5 minutes, upon
                    successful verification of your proof of transaction. If you
                    encounter any further diffculties, contact our friendly
                    Customer Support Representatives via our Live Chat function.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    WHAT IS THE MINIMUM AMOUNT REQUIRED FOR A DEPOSIT?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    A newly-registered players are required to post an initial
                    deposit in the amount of One Thousand Pesos (PhP1,000.00).
                    Subsequent deposits or top-ups must be at least Five Hundred
                    Pesos (PhP500.00).
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>HOW TO WITHDRAW?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Login to your SG8 account and click on “Dashboard” in the
                    side menu. Under “Dashboard”, click on “Wallet” located on
                    the left of the page. Follow all the steps as shown and fill
                    in the necessary details of your transaction. Once done,
                    click the “Submit” to proceed with your withdrawal.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    HOW MUCH CAN I WITHDRAW PER DAY?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Kindly contact our Customer Support Representatives via our
                    Live Chat function to inquire on the designated daily
                    withdrawal limit.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    HOW LONG WILL IT TAKE TO PROCESS MY WITHDRAWAL?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Upon successful verification of your withdrawal request, the
                    funds will be transferred to your indicated bank account
                    within 10 minutes.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    CAN I WITHDRAW FOR OTHER PLAYERS USING MY ACCOUNT NAME?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    No. You are only allowed to use bank accounts under your
                    name for withdrawal transactions. Withdrawals using bank
                    accounts under a different name will be subject to approval.
                    Please contact our Customer Service Representatives via our
                    Live Chat function to inquire more regarding the use of bank
                    accounts under a separate name.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    ARE MY TRANSACTION DETAILS SECURE?
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    All transaction information trasmitted over SG8’s servers
                    are highly secure and encrypted.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
        </Tabs>
      </section>
    </>
  );
}
