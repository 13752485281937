import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import MainSlider from "../../snippets/MainSlider";
import { Helmet } from "react-helmet";
import { currentPath, currentDomain } from "../../App";
import { useTranslation } from "react-i18next";

export default function ReferralBonus() {
  const { t, i18n } = useTranslation();
  const title = "Referral Bonus";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("ReferralBonus");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("ReferralBonus");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Referral Rewards | Earn Extra Bonuses for Each Friend</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Invite your friends to SG8Bet and earn up to bonuses. Share the excitement and enjoy extra rewards together. Refer now!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        <div className="main-wrapper-promo">
          <div className="main-content-area">
            <h2>{t("promotion.3.title.1")}</h2>
            <p>
              {t("promotion.3.desc.1")}
            </p>
            <br />
            <h2>{t("promotion.3.title.2")}</h2>
            <ul>
              <li>
                {t("promotion.3.desc.2")}
              </li>
              <li>
                {t("promotion.3.desc.3")}
              </li>
              <li>
                {t("promotion.3.desc.4")}
                <ul>
                  <li>{t("promotion.3.subdesc.1")}</li>
                  <li>{t("promotion.3.subdesc.2")}</li>
                  <li>{t("promotion.3.subdesc.3")}</li>
                  <li>{t("promotion.3.subdesc.4")}</li>
                  <li>{t("promotion.3.subdesc.5")}</li>
                  <li>{t("promotion.3.subdesc.6")}</li>
                  <li>{t("promotion.3.subdesc.7")}</li>
                </ul>
              </li>
              <li>
                {t("promotion.3.desc.6")}
              </li>
            </ul>
            <br />
            <h2>{t("promotion.3.title.2")}</h2>
            <ul>
              <li>
               {t("promotion.3.desc.7")}
              </li>
              <li>
                {t("promotion.3.desc.8")}
              </li>
              <li>
                {t("promotion.3.desc.9")}
                <ul>
                  <li>{t("promotion.3.subdesc.8")}</li>
                  <li>{t("promotion.3.subdesc.2")}</li>
                  <li>{t("promotion.3.subdesc.9")}</li>
                  <li>{t("promotion.3.subdesc.10")}</li>
                  <li>{t("promotion.3.subdesc.5")}</li>
                  <li>{t("promotion.3.subdesc.11")}</li>
                  <li>{t("promotion.3.subdesc.12")}</li>
                </ul>
              </li>
              <li>
                {t("promotion.3.desc.10")}
              </li>
            </ul>
            <br />
            <h2>{t("promotion.3.title.3")}</h2>
            <ol>
              <li>{t("promotion.3.desc.11")}</li>
              <li>
                {t("promotion.3.desc.12")}
              </li>
              <li>
                {t("promotion.3.desc.13")}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}
