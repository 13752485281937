import React, { useState, useEffect } from "react";
import SlickHomebanner from "./snippets/MainSlider";
import FeaturedGamesList from "./snippets/FeaturedGameList";
import ProviderListHome from "./snippets/ProviderListHome";
import { launchGame, AlertModal } from "./pages/LiveCasino";
import { getCookie, setCookie } from './cookies';
import { Helmet } from "react-helmet";
// import trackPage from './analytics';
// import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
export const currentPath = window.location.pathname;
export const currentDomain = window.location.origin;


//https switch
if (window.location.protocol === "http:") {
  // window.location.href = window.location.href.replace("http://", "https://");
}
if (window.location === "https://thai.sg8game.com/")
{
  window.location.href = "https://sg8.fun/";
}

//version control-ish
const current_version = '1.0.21';
if (localStorage.getItem('current_version') != current_version) {
  localStorage.setItem('current_version', current_version);
  window.location.href = '?v='+ current_version;
  window.location.reload(true);
}
// const gtag = process.env.REACT_APP_GTAG;

// const tagManagerArgs = {
//   gtmId: gtag
// };

// TagManager.initialize(tagManagerArgs);

// const usePageTracking = () => {
//   const location = useLocation();

//   useEffect(() => {
//     trackPage(location.pathname + location.search);
//   }, [location]);
// };
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function App() {
  const [loading, setLoading] = useState(true);
  const sliderCurrentPage = "all";
  const bannerCurrentPage = "all";

  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

   useEffect(() => {
    const language = getCookie('i18next') || 'th';
    i18n.changeLanguage(language);
    setCookie('i18next', language, 365); 
  }, [i18n]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  let mobile = isMobile ? 1 : 0;
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="sg8bet.com is a online betting brand, offering world class sports betting products and services to Asia Pacific markets."
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />
      <div>
        <div className="main-wrapper">
          <div className="main-content-area">
            <SlickHomebanner
              sliderCurrentPage={sliderCurrentPage}
              bannerCurrentPage={bannerCurrentPage}
            />
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>{t('home.1')}</span> <h2>{t('home.2')}</h2>
                </div>
                <div className="ProviderListHome"></div>
                <div className="c2a-wrapper">
                  <a href="/slots/" className="yellow-btn">
                    {t('button.3')}
                  </a>
                </div>
              </div>
              <ProviderListHome />
              <div className="hottest-gamelist">
                <FeaturedGamesList />
              </div>
            </div>
            <div className="featured-slots-container">
              <div className="slots-headlines-container">
                <div className="h2-text-style">
                  <span>{t('home.3')}</span> <h2>{t('home.4')}</h2>
                </div>
                <div className="c2a-wrapper">
                  <a href="/live-casino/" className="yellow-btn">
                    {t('button.3')}
                  </a>
                </div>
              </div>
              <div className="hotLDGames">
                <div className="gameLD">
                  <img src="/images/livedealer/main/world-entertainment-main.webp" alt="evolution games baccarat" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        isMobile ? "20002" : "20001",
                        isMobile,
                        t('lang.1'),
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert,
                        t
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/livedealer/main/evolution-casino-main.webp" alt="Evolution Games Crazy Time" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "25000",
                        isMobile,
                        t('lang.1'),
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert,
                        t
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img
                    src="/images/livedealer/main/og-casino-main.webp"
                    alt="Evolution Game First Person Baccarat"
                  />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "31101",
                        isMobile,
                        t('lang.1'),
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert,
                        t
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/livedealer/main/ae-casino-main.webp" alt="AE Casino on Sg8Bet" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "46001",
                        true,
                        t('lang.1'),
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert,
                        t
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>
                <div className="gameLD">
                  <img src="/images/livedealer/main/sagaming-casino-main.webp" alt="SA Gaming on SG8 Casino" />
                  <button
                    className="yellow-btn"
                    onClick={() =>
                      launchGame(
                        "",
                        "36001",
                        true,
                        t('lang.1'),
                        0,
                        true,
                        setLaunching,
                        setCurrentFrame,
                        setAnimationComplete,
                        showAlert,
                        t
                      )
                    }
                  >
                    {t('button.1')}
                  </button>
                </div>

              </div>
            </div>
            <div className="featured-slots-container">
              <div className="service-selector">
                <div className="services-selector-box">
                  <img src="/images/slot-selector.webp" alt="Best Online Slot in the Philippines at SG8 Casino | JILI Games" />
                  <span>{t('footer-nav.7')}</span>
                  <a href="/slots/pgsoft/" className="yellow-btn">
                    {t('button.1')}
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/ld-selector copy.webp" alt="Real Person Live Casino Live at SG8 Casino" />
                  <span>{t('footer-nav.8')}</span>
                  <a href="/live-casino/" className="yellow-btn">
                    {t('button.1')}
                  </a>
                </div>
                <div className="services-selector-box">
                  <img src="/images/sprotsbook-selector.webp" alt="Live Betting Sportsbook at SG8 Casino" />
                  <span>{t('footer-nav.9')}</span>
                  <a href="/sportsbook/" className="yellow-btn">
                    {t('button.2')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default App;
