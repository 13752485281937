import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import {currentPath, currentDomain} from '../../App';

const BlogList = [
    {
        title: "Latest Casino Promotions",
        content: "Discover the latest promotions and bonuses available at SG8 Casino Filipino.",
        images: {
            mobile: "/images/promotions-at-sg8-mobile.webp",
            desktop: "/images/promotions-at-sg8.webp"
        },
        category: "Promotions",
        link: "/news/latest-promotion/",
        priority: 2 // Lower number means higher priority
    },
    {
        title: "Unlock the Full Experience Download our Mobile App Now!",
        content: "Our app offers a user-friendly interface, fast performance, and the ultimate convenience for gaming on the go. Don't miss out—download today and elevate your gaming experience!",
        images: {
            mobile: "/images/download/apkbanner-mbl.webp",
            desktop: "/images/download/apkbannerv1.webp"
        },
        category: "News",
        link: "/news/download-sg8-casino-mobile-app/",
        priority: 1 // Higher priority
    },
];

// Sort the BlogList by priority
const sortedBlogList = BlogList.sort((a, b) => a.priority - b.priority);

function Blog() {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    // Filter the blog list based on the selected category and search query
    const filteredBlogList = sortedBlogList.filter(blog => {
        const matchesCategory = selectedCategory === 'All' || blog.category === selectedCategory;
        const matchesSearch = blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                              blog.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
                              blog.category.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesCategory && matchesSearch;
    });

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredBlogList.length / itemsPerPage);

    // Get the blogs to display on the current page
    const displayedBlogs = filteredBlogList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Update suggestions as the user types
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length > 1) {
            const matchedSuggestions = sortedBlogList.filter(blog =>
                blog.title.toLowerCase().includes(query.toLowerCase()) ||
                blog.content.toLowerCase().includes(query.toLowerCase()) ||
                blog.category.toLowerCase().includes(query.toLowerCase())
            ).map(blog => ({ title: blog.title, link: blog.link }));
            setSuggestions(matchedSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    // Handle page change
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>SG8 Casino Filipino Ultimate Gaming Experience | News & Updates</title>
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Latest news and updates from SG8 Casino Filipino. Ultimate gaming experience with Jili Games. Join us now!"/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>
            <div className="main-wrapper">
                <div className="main-content-area">
                    <div className="page-headlines-container">
                        <div className="pageHeaderText">
                            <h1>SG8 Casino Filipino</h1>
                            <p>Check our latest news and updates!</p>
                        </div>
                    </div>
                    <div className='filter-search-container'>
                        <div className="search-container">
                            <label htmlFor="search-bar" className='search-bar-blog-page-label'>What are you looking for? </label>
                            <input
                                type="text"
                                id="search-bar"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                placeholder="Search blog posts..."
                                autoComplete="off" // Disable autocomplete
                            />
                            {suggestions.length > 0 && (
                                <ul className="suggestions-list">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index}>
                                            <a href={suggestion.link}>{suggestion.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className="filter-container">
                            <label htmlFor="category-filter" className='filter-blog-page-label'>Filter : </label>
                            <select
                                id="category-filter"
                                value={selectedCategory}
                                onChange={e => setSelectedCategory(e.target.value)}
                            >
                                <option value="All">All</option>
                                <option value="News">News</option>
                                <option value="Games">Games</option>
                                <option value="Promotions">Promotions</option>
                            </select>
                        </div>
                    </div>
                    <div className='blog-count'>
                        <p>Showing {Math.min(currentPage * itemsPerPage, filteredBlogList.length)} results out of {filteredBlogList.length}</p>
                    </div>
                    <div className='blog-container'>
                        {displayedBlogs.map((blog, index) => (
                            <div className='blog-list' key={index}>
                                <picture>
                                    <source media="(min-width: 840px)" srcSet={blog.images.desktop} />
                                    <img src={blog.images.mobile} alt={blog.title} />
                                </picture>
                                <div className='blog-list-content'>
                                    <span className='category'>{blog.category}</span>
                                    <h2>{blog.title}</h2>
                                    <p>{blog.content}</p>
                                    <a href={blog.link}><button>Read More</button></a>
                                </div>
                            </div>
                        ))}
                    </div>
                    {totalPages > 1 && (
                        <div className="pagination-container">
                            {currentPage > 1 && (
                                <button onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                            )}
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={currentPage === index + 1 ? 'active' : ''}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            {currentPage < totalPages && (
                                <button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export { BlogList };
export default Blog;
