import React, { useState, useEffect, useCallback, useRef } from "react";
import { tokenURL, apiJSONURL, httpsLaunchURL } from "../pages/LiveCasino";
import { useTranslation } from 'react-i18next';
import axios from "axios";

const SBLaunch = ({
  gameId,
  lang,
  logoutURL,
  onOpenNewWindow,
  showIframe = true,
}) => {
  const [launchURL, setLaunchURL] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [launching, setLaunching] = useState(true);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const totalFrames = 77; // The last frame number
  const { t, i18n } = useTranslation();
  const imageRef = useRef(null);


  useEffect(() => {
    if (!lang) {
      lang = t('lang.1');
    }
  }, [t, lang]);
  const fetchEncryptedUsername = useCallback(async (username) => {
    try {
      const response = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      );
      if (response.data.token) {
        return response.data.token;
      } else {
        throw new Error("Token not received from the API");
      }
    } catch (error) {
      console.error("Error in fetchEncryptedUsername:", error);
      throw error;
    }
  }, []);

  const fetchSessionToken = useCallback(async (encryptedUsername, gameId) => {
    try {
      const response = await axios.get(
        `${tokenURL}?u=${encryptedUsername}&g=${gameId}&t=transfer&m=mobile`
      );
      if (response.data.token) {
        return response.data.token;
      } else {
        throw new Error("Session token not received from the API");
      }
    } catch (error) {
      console.error("Error in fetchSessionToken:", error);
      throw error;
    }
  }, []);

  const handleLaunchGame = useCallback(
    async (username, gameId, isMobile) => {
      try {
        //const encryptedUsername = localStorage.getItem('encryptedUsername');
        const encryptedUsername = await fetchEncryptedUsername(username);
        const sessionToken = await fetchSessionToken(encryptedUsername, gameId);
        const mobileParam = isMobile ? "&m=1" : ""; 
        const finalLaunchURL = `${httpsLaunchURL}?s=${sessionToken}&l=${lang}${mobileParam}`;
        setTimeout(() => {
          setLaunchURL(finalLaunchURL);
          setIsButtonVisible(true);
          setLaunching(false);
          console.log("Launching game with URL:", finalLaunchURL);
        }, 1000); // Delay of 1 second
      } catch (error) {
        console.error("Error launching game:", error);
        alert("Error launching game. Please try again.");
        setLaunching(false);
      }
    },
    [fetchEncryptedUsername, fetchSessionToken, lang]
  );

  useEffect(() => {
    const checkUserLogin = () => {
      const username = localStorage.getItem("username");
      const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

      if (username) {
        if (username.startsWith("SG8CP") && gameId.startsWith("62")) {
          console.error("Not allowed.");
          alert("Not allowed.");
          return;
        }

        const loggedIn = document.body.classList.contains("isLoggedIn");
        if (loggedIn) {
          handleLaunchGame(username, gameId, isMobile);
        } else {
          setLaunchURL(logoutURL);
          setLaunching(false);
        }
      } else {
        setLaunchURL(logoutURL);
        setLaunching(false);
      }
    };
    checkUserLogin();
  }, [gameId, handleLaunchGame]);

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 30); // Adjust the interval to 30 milliseconds for 2x speed
    }
    return () => clearInterval(interval);
  }, [launching]);

  return (
    <>
      {showIframe ? (
        <div style={{ position: "relative", width: "100%", height: "800px" }}>
          {launchURL ? (
            <>
              <iframe
                className="gameIframe"
                src={launchURL}
                title="Game Iframe"
                style={{ width: "100%", height: "100%", border: "none" }}
                allowFullScreen
              />
            </>
          ) : (
            <div
              className="overlay"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <div className="loadingBar">
                <div className={`spinner ${animationComplete ? "pop" : ""}`}>
                  <img
                    ref={imageRef}
                    src={`/images/frameLoading/sg8logo_${String(
                      currentFrame
                    ).padStart(5, "0")}.png`}
                    alt="Loading..."
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        isButtonVisible && (
          <button onClick={onOpenNewWindow}>{t('label.15')}</button>
        )
      )}
    </>
  );
};

export default SBLaunch;