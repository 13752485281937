import React from "react";
import { Helmet } from "react-helmet";
import PaymentMethod from './../snippets/PaymentMethod';
import { currentPath, currentDomain } from '../App';
import FishingGallery from "../snippets/FishingGameList";

function Fishing() {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>
        SG8Bet
        </title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the ultimate fishing adventure with our exciting online fishing games!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
         <div className="fishing-content-area">
        <FishingGallery />
         </div>
        </div>
      </div>

      <div className="main-content-wrapper">
        <div className="text-content-area">
          <h2>Welcome to SG8 Casino Philippines: Gaming Excellence</h2>
          <p>Welcome to SG8 Casino Philippines, your premier destination for online gaming. Dive into thrilling entertainment and lucrative opportunities with our diverse selection of games. Backed by PAGCOR's seal of approval, SG8 Casino guarantees a top-notch gaming experience that will keep you coming back for more. We prioritize your safety and security, with robust measures and advanced encryption technology to keep your information confidential. Our dedicated support team is available 24/7 to assist with any questions or concerns.</p>
          <h2>Thrill of Live Casino and Sports Betting</h2>
          <p>Experience the excitement of our Live Casino, powered by Evolution Gaming. Enjoy classic table games like Baccarat and Blackjack, or engage with live dealers in games like Roulette and Crazy Time. Our high-definition live streaming technology brings the action to you, no matter where you are. We cater to both casual players and high rollers with a wide range of betting limits. Our comprehensive sportsbook, powered by Pinnacle, offers a wide range of betting options for major league matchups and international tournaments. With real-time updates and live betting options, you won't miss a moment of the action. Our intuitive interface makes it easy to navigate and place your bets quickly and securely.</p>
          <h2>Explore Online Slots and Exclusive Promotions</h2>
          <p>Explore our vast selection of online slots, from classic fruit machines to modern video slots with cutting-edge graphics and innovative features. Discover exclusive titles like JILI Slot 777 and popular games from leading providers like RTG. Our regularly updated game library ensures you'll always find something new and exciting. Take advantage of our exclusive promotions and bonuses, designed to enhance your gaming experience and maximize your chances of winning big. Whether you're a new player looking to boost your bankroll or a loyal customer seeking rewards, SG8 Casino has something for everyone. Enjoy generous deposit bonuses, free spins, cashback offers, and more. Our VIP program offers even more perks and rewards. Join us today and experience the thrill of winning big at SG8 Casino, where the fun never stops!</p>
          <PaymentMethod />
          <div></div>
        </div>
      </div>
    </>
  );
}

export default Fishing;