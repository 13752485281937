import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { useTranslation } from "react-i18next";

export default function JiliSuperTournaments() {
  const { t, i18n } = useTranslation();
  const title = "JILI Super Tournaments";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("JiliSuperTournaments");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("JiliSuperTournaments");
  const [loading, setLoading] = useState(true);
  const eventLink = t("promotion.5.link");
  
  const handleLinkClick = () => {
    window.open(eventLink);
    };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet: JILI Super Tournaments</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8Bet with JILI Super Tournaments!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

        <div className="main-wrapper-promo">
          <div className="main-content-area">
            <h2>{t("promotion.5.title.1")}</h2>
            <p>
              {t("promotion.5.desc.1")}
            </p>
            <br />

            <h2>{t("promotion.5.title.2")}</h2>
            <ul>
              <li>{t("promotion.5.desc.2")}</li>
              <li>{t("promotion.5.desc.3")}</li>
              <li>{t("promotion.5.desc.4")}</li>
              <li>
                {t("promotion.5.desc.5")}
              </li>
              <li>{t("promotion.5.desc.6")}</li>
              <li>{t("promotion.5.desc.7")}</li>
              <li>
                {t("promotion.5.desc.8")}
              </li>
              <li>{t("promotion.5.desc.9")}</li>
              <li>{t("promotion.5.desc.10")}</li>
            </ul><br/>
            <a onClick={handleLinkClick} className="yellow-btn">VIEW FULL T&C</a>
          </div>
        </div>
      </div>
    </>
  );
}
