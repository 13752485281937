import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { getCookie } from "../../cookies";

export default function YggMasTree() {
  const title = "YGG-Mas Tree";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("yggmas");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("yggmas");
  
  // Track language in state
  const [isThaiLanguage, setIsThaiLanguage] = useState(getCookie('i18next') === 'th');

  // Watch for changes in the cookie
  useEffect(() => {
    const checkLanguageChange = () => {
      const currentLanguage = getCookie('i18next') === 'th';
      if (currentLanguage !== isThaiLanguage) {
        setIsThaiLanguage(currentLanguage);
      }
    };

    // Set up a timer to check the cookie periodically
    const intervalId = setInterval(checkLanguageChange, 1000); // Check every second

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, [isThaiLanguage]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>YGG-Mas Tree</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Spade Gaming Play and Win"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />
        
        {isThaiLanguage && (
            <div className="main-wrapper-promo">
                <div className="main-content-area">
                    <h2>ระยะเวลากิจกรรม</h2>
                    <p>
                        <b>1. ต้นคริสต์มาส YGG 1 – (ดรอปรางวัล):</b><br/>
                        วันที่ 2 ธันวาคม 2567 16:00 GMT+7 - วันที่ 9 ธันวาคม 2567 05:59 GMT+7
                    </p>
                    <p>
                        <b>2. ต้นคริสต์มาส YGG 2 – (ดรอปรางวัล):</b><br/>
                        วันที่ 9 ธันวาคม 2567 16:00 GMT+7 - วันที่ 16 ธันวาคม 2567 05:59 GMT+7
                    </p>
                    <p>
                        <b>3. ต้นคริสต์มาส YGG 3 – (ดรอปรางวัล):</b><br/>
                        วันที่ 16 ธันวาคม 2567 16:00 GMT+7 - วันที่ 23 ธันวาคม 2567 05:59 GMT+7
                    </p>
                    <p>
                        <b>4. ต้นคริสต์มาส YGG 4 – (ดรอปรางวัล):</b><br/>
                        วันที่ 23 ธันวาคม 2567 16:00 GMT+7 - วันที่ 30 ธันวาคม 2567 05:59 GMT+7
                    </p>
                    <p>
                        <b>ต้นคริสต์มาส YGG 5 – (ดรอปรางวัล):</b><br/>
                        วันที่ 30 ธันวาคม 2567 16:00 GMT+7 - วันที่ 6 มกราคม 2567 05:59 GMT+7
                    </p>
                    <div style={{ height: "20px" }}></div>
                    <h2>เกมส์ที่เข้าร่วม</h2>
                    <p>
                        <b>1. ต้นคริสต์มาส YGG 1 – (ดรอปรางวัล)</b><br/>
                        <ul>
                            <li>Valley of the Dead</li>
                            <li>3 Gladiators vs Caesar</li>
                            <li>Holmes & the Stolen Stones (Jackpot Game)</li>
                            <li>Christmas Plaza DoubleMax</li>
                            <li>Golden Donkey XMAS</li>
                        </ul>
                    </p>
                    <p>
                        <b>2. ต้นคริสต์มาส YGG 2 – (ดรอปรางวัล)</b><br/>
                        <ul>
                            <li>Valley of the Dead</li>
                            <li>Golden Fish Tank</li>
                            <li>Frost Queen Jackpots (Jackpot Game)</li>
                            <li>Winterberries</li>
                            <li>Sugar Bomb MultiBoost</li>
                            <li>Double Fruit Frenzy DoubleMax</li>
                        </ul>
                    </p>
                    <p>
                        <b>3. ต้นคริสต์มาส YGG 3 – (ดรอปรางวัล)</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Vikings Go Berzerk</li>
                            <li>Rock Star Santa MultiMaxTM</li>
                            <li>Winterberries 2</li>
                            <li>American Outlaws MultiMaxTM</li>
                            <li>Big Game Fishing TopHit</li>
                        </ul>
                    </p>
                    <p>
                        <b>4. ต้นคริสต์มาส YGG 4 – (ดรอปรางวัล)</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Raptor DoubleMax</li>
                            <li>Carol of the Elves</li>
                            <li>Christmas Plaza DoubleMax</li>
                            <li>90k Yeti GigaBlox</li>
                            <li>Book Extreme</li>
                        </ul>
                    </p>
                    <p>
                        <b>5. ต้นคริสต์มาส YGG 5 – (ดรอปรางวัล)</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Vikings Go To Egypt</li>
                            <li>Frost Queen Jackpots (Jackpot Game)</li>
                            <li>Valley of the Gods</li>
                            <li>Holmes & the Stolen Stones (Jackpot Game)</li>
                            <li>Giga Bass GigaBloxTM</li>
                        </ul>
                    </p>
                    <div style={{ height: "20px" }}></div>
                    <h2>รายละเอียดกิจกรรม</h2>
                    <p><b>ต้นคริสต์มาส YGG – (ดรอปรางวัล):</b></p>
                    <ol>
                        <li>เงินรางวัลรวมกิจกรรมคือ 150,000 EUR</li>
                        <li>กิจกรรมนี้ไม่กําหนดขั้นต่ําในการเข้าร่วม</li>
                        <li>เฉพาะการเดิมพันในโหมดเล่นจริงเท่านั้นที่สามารถเข้าร่วมกิจกรรมได้</li>
                        <li>เพื่อที่จะเข้าร่วมแคมเปญ ผู้เล่นต้องยินยอมเพื่อที่จะเข้าร่วมแคมเปญโดยการคลิก “เข้าร่วม” ในหน้าป็อปอัพผู้เล่นสามารถเข้าร่วมโดยการเข้าไปที่เกมที่เป็นหนึ่งในเกมที่เข้าร่วมในระหว่างแคมเปญ และร่วมเดิมพันในเกมนั้นที่เข้าร่วม โดยไม่มีค่าใช้จ่ายเพิ่มเติมเพื่อเข้าร่วมแคมเปญ</li>
                        <li>เดิมพันในเกมที่เข้าร่วมกิจกรรมในช่วงเวลาที่จัดกิจกรรมนี้ ซึ่งจะสามารถลุ้นรับรางวัลสุ่มจากเงินรางวัลรวมได้</li>
                        <li>ในหนึ่งรอบการหมุนสามารถรับได้เพียง 1 รางวัลสุ่มเท่านั้น</li>
                        <li>เงินรางวัลจะถูกมอบในช่วงระยะเวลาแคมเปญ รางวัลที่มีมูลค่ามากที่สุด หนึ่งในสามรางวัลใหญ่ (รางวัลสูงสุด) จะถูกมอบในช่วงระยะเวลาสุดท้ายของแคมเปญซึ่งจะเท่ากับ5%ของระยะเวลาทั้งหมดของแคมเปญ (ยกตัวอย่างเช่น ระยะเวลาแคมเปญมี 100 ชั่วโมง หนึ่งในสามรางวัลใหญ่จะถูกมอบในช่วงเวลา 5 ชั่วโมงสุดท้ายของแคมเปญ)</li>
                        <li>จํานวนคงเหลือของรางวัลจะอัพเดตทุกๆ 60 วินาที</li>
                        <li>เงินรางวัลจะเครดิตเป็นเงินสด</li>
                        <li>ผู้เล่นสามารถชนะรางวัลสุ่มได้มากกว่า 1 รางวัลตลอดช่วงระยะเวลากิจกรรม</li>
                    </ol>
                    <div style={{ height: "20px" }}></div>
                    <h2>เงินรางวัลรวม</h2>
                    <ol>
                        <li>– รางวัลรวมสําหรับ: (เงินรางวัลตั้งค่าเป็นสกุลเงิน EUR และเงินรางวัลอาจได้รับผลกระทบจากความผันผวนของค่าเงิน))
                            <b>ต้นคริสต์มาส YGG – (ดรอปรางวัล):</b>
                            <table>
                                <tbody>
                                    <tr className="min">
                                        <td>Min. bet</td>
                                        <td>0</td>
                                        <td></td>
                                    </tr>
                                    <tr className="tablehead">
                                        <td>Position</td>
                                        <td>Prize Count</td>
                                        <td>Prize (€)</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>1</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>2</td>
                                        <td>1,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>7</td>
                                        <td>500</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>55</td>
                                        <td>100</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>110</td>
                                        <td>50</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>425</td>
                                        <td>20</td>
                                    </tr>
                                    <tr className="total">
                                        <td>Total</td>
                                        <td>600</td>
                                        <td>30,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>เงินรางวัลรวมกิจกรรมนี้ตั้งค่าเป็นสกุลเงิน EUR และอาจได้รับผลกระทบจากความผันผวนของค่าเงิน</li>
                        <li>เงินรางวัลที่ชนะจะถูกเครดิตเข้าบัญชีคาสิโนของผู้เล่นโดยอัตโนมัติทันที</li>
                        <li>รางวัลจะถูกจ่ายตามที่ระบุในเงินรางวัลรวม</li>
                        <li>Yggdrasil ขอสงวนสิทธิ์ในการตัดสินคะแนนเป็นโมฆะ หรือการไม่จ่ายเงินรางวัล หากผลคะแนนหรือการจ่าย ทั้งหมดหรือบางส่วนเกิดจากข้อผิดพลาดหรือความผิดพลาดทางเทคนิคที่เห็นได้ชัด (รวมถึงการจ่ายเงินในเกมที่ไม่ถูกต้อง) ไม่ว่าจะเกิดจากเครื่องมือที่ผิดพลาดหรือความผิดพลาดโดยมนุษย์ในส่วนใดของเกมที่เข้าร่วม เราขอสงวนสิทธิ์ในการตัดสินคะแนนเป็นโมฆะ ตลอดจนไม่จ่ายเงินรางวัลในกรณีที่คะแนนทั้งหมดหรือบางส่วนเป็นผลมาจากการทุจริต หรือสมรู้ร่วมคิดกับผู้เล่นคนอื่นเพื่อทําการทุจริต</li>
                        <li>Azure Tech (Yggdrasil) ขอสงวนสิทธิ์ในการยกเลิก หรือแก้ไขกิจกรรมโปรโมชั่นได้ตลอดระยะเวลา</li>
                        <li>รางวัลทั้งหมดในกิจกรรมนี้จะถูกหักจากบิลรายเดือนตามผู้ประกอบการที่เกี่ยวข้อง โปรดทราบว่ารางวัลเงินสดมอบให้กับผู้เล่นโดยอัตโนมัติรางวัลที่ดังกล่าวจะเพิ่มไปยังยอดคงเหลือของผู้เล่นตามสกุลเงินของบัญชีผู้เล่น การหักเงินในบิลเรียกเก็บค่าใช้จ่าย จะถูกเครดิตโดย Azure Tech (Yggdrasil)ตามสกุลเงินของใบแจ้งหนี้ (โดยทั่วไปคือ EUR) ตามมูลค่า EUR ของรางวัลรวมที่ชนะ</li>
                    </ol>
                    <div style={{ height: "20px" }}></div>
                    <h2>ข้อกําหนดโปรโมชั่นและรายละเอียด</h2>
                    <p>การเข้าร่วมกิจกรรมโปรโมชั่น คุณต้องเห็นด้วยกับเงื่อนไขดังนี้:</p>
                    <ol>
                        <li>ช่วงระยะเวลากิจกรรม โปรดวางเกมที่เข้าร่วมไว้ที่ ท็อปสิบเกมฮิต บนหน้าเว็บไซต์ของคุณ</li>
                        <li>ช่วงระยะเวลากิจกรรม คุณสามารถแสดงแบนเนอร์โปรโมชั่นนี้ได้อย่างชัดเจนบนหน้าเว็บไซต์ของคุณ</li>
                        <li>ต้องประชาสัมพันธ์กิจกรรมโปรโมชั่นนี้ ผ่านช่องทางการตลาด ไม่ว่าจะเป็น อีเมล, SMS, การแชท หรือการโทรเชิญชวน</li>
                        <li>ต้องแสดงเงื่อนไขและข้อกําหนดของกิจกรรมนี้ อย่างชัดเจนบนหน้าเว็บไซต์ของคุณ</li>
                        <li>ริเริ่มผลิตสื่อการตลาด และประชาสัมพันธ์กิจกรรมนี้</li>
                    </ol>
                </div>
            </div>
        )}
        
        {!isThaiLanguage && (
            
            <div className="main-wrapper-promo">
                <div className="main-content-area">
                    <h2>Campaigns Runs as Follow</h2>
                    <p>
                        <b>1. YGG-MAS TREE Week 1- (Prize Drop):</b><br/>
                        2 Dec 2024 17:00 UTC+8 - 9 Dec 2024 06:59 UTC+8
                    </p>
                    <p>
                        <b>2. YGG-MAS TREE Week 2- (Prize Drop):</b><br/>
                        9 Dec 2024 17:00 UTC+8 - 16 Dec 2024 06:59 UTC+8
                    </p>
                    <p>
                        <b>3. YGG-MAS TREE Week 3- (Prize Drop):</b><br/>
                        16 Dec 2024 17:00 UTC+8 - 23 Dec 2024 06:59 UTC+8
                    </p>
                    <p>
                        <b>4. YGG-MAS TREE Week 4- (Prize Drop):</b><br/>
                        23 Dec 2024 17:00 UTC+8 - 30 Dec 2024 06:59 UTC+8
                    </p>
                    <p>
                        <b>5. YGG-MAS TREE Week 5- (Prize Drop):</b><br/>
                        30 Dec 2024 17:00 UTC+8 - 6 Jan 2025 06:59 UTC+8
                    </p>
                    <div style={{ height: "20px" }}></div>
                    <h2>Participating Games</h2>
                    <p>
                        <b>1. YGG-MAS TREE Week 1- (Prize Drop):</b><br/>
                        <ul>
                            <li>Valley of the Dead</li>
                            <li>3 Gladiators vs Caesar</li>
                            <li>Holmes & the Stolen Stones (Jackpot Game)</li>
                            <li>Christmas Plaza DoubleMax</li>
                            <li>Golden Donkey XMAS</li>
                        </ul>
                    </p>
                    <p>
                        <b>2. YGG-MAS TREE Week 2- (Prize Drop):</b><br/>
                        <ul>
                            <li>Valley of the Dead</li>
                            <li>Golden Fish Tank</li>
                            <li>Frost Queen Jackpots (Jackpot Game)</li>
                            <li>Winterberries</li>
                            <li>Sugar Bomb MultiBoost</li>
                            <li>Double Fruit Frenzy DoubleMax</li>
                        </ul>
                    </p>
                    <p>
                        <b>3. YGG-MAS TREE Week 3- (Prize Drop):</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Vikings Go Berzerk</li>
                            <li>Rock Star Santa MultiMaxTM</li>
                            <li>Winterberries 2</li>
                            <li>American Outlaws MultiMaxTM</li>
                            <li>Big Game Fishing TopHit</li>
                        </ul>
                    </p>
                    <p>
                        <b>4. YGG-MAS TREE Week 4- (Prize Drop):</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Raptor DoubleMax</li>
                            <li>Carol of the Elves</li>
                            <li>Christmas Plaza DoubleMax</li>
                            <li>90k Yeti GigaBlox</li>
                            <li>Book Extreme</li>
                        </ul>
                    </p>
                    <p>
                        <b>5. YGG-MAS TREE Week 5- (Prize Drop):</b><br/>
                        <ul>
                            <li>Golden Fish Tank Party</li>
                            <li>Vikings Go To Egypt</li>
                            <li>Frost Queen Jackpots (Jackpot Game)</li>
                            <li>Valley of the Gods</li>
                            <li>Holmes & the Stolen Stones (Jackpot Game)</li>
                            <li>Giga Bass GigaBloxTM</li>
                        </ul>
                    </p>
                    <div style={{ height: "20px" }}></div>
                    <h2>Campaign Details</h2>
                    <p><b>YGG-MAS TREE - (Prize Drop)</b></p>
                    <ol>
                        <li>Total Prize pool for the campaign is €150,000.</li>
                        <li>There is no minimum bet required to participate in the campaign.</li>
                        <li>Only bets made in play for real mode qualify for the campaign.</li>
                        <li>To participate in the campaign, players shall confirm that they wish to take part in any of the stages of the campaign by clicking “join now” in the pop-up window. Participating players will take part in the campaign by placing a bet in the participating games during the campaign period. No additional payment is required to take part in the campaign.</li>
                        <li>Any bet placed on the participating games during the campaign period can trigger a random mystery prize from the prize pool.</li>
                        <li>Each bet can win only 1 random prize reward.</li>
                        <li>Prizes will be awarded throughout the campaign period. A minimum of one of the three most valuable prizes (a “top prize”) will be awarded during the final period of the campaign, such period being equal to 5% of the overall campaign period (for example if the campaign period is 100 hours, a minimum of one top prize will be awarded during the final 5 hours).</li>
                        <li>Remaining number of prizes is updated every 60 seconds.</li>
                        <li>Prizes will be credited as cash.</li>
                        <li>Players can win more than 1 random prize reward during the campaign.</li>
                    </ol>
                    <div style={{ height: "20px" }}></div>
                    <h2>Prizes</h2>
                    <ol>
                        <li>– Prize pool for: (Prize pool for this campaign is set up in EUR and might be subject to currency conversion fluctuations)
                            <b>YGG-MAS TREE - (Prize Drop)</b>
                            <table>
                                <tbody>
                                    <tr className="min">
                                        <td>Min. bet</td>
                                        <td>0</td>
                                        <td></td>
                                    </tr>
                                    <tr className="tablehead">
                                        <td>Position</td>
                                        <td>Prize Count</td>
                                        <td>Prize (€)</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>1</td>
                                        <td>5,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>2</td>
                                        <td>1,000</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>7</td>
                                        <td>500</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>55</td>
                                        <td>100</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>110</td>
                                        <td>50</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>425</td>
                                        <td>20</td>
                                    </tr>
                                    <tr className="total">
                                        <td>Total</td>
                                        <td>600</td>
                                        <td>30,000</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>Prize pool for this campaign is set up in EUR and might be subject to currency conversion fluctuations.</li>
                        <li>All prizes won will be automatically credited to the players’ casino accounts instantly.</li>
                        <li>Prizes will be paid out as per the prize pool tab.</li>
                        <li>Yggdrasil reserves the right to not pay out a prize where the win results from any obvious error, mistake or technical fault (including incorrect game pay-outs) whether caused by a machine or human error in respect of any of the participating games. We further reserve the right to not pay out a prize where, in our opinion, the win results from cheating or collusion with other players.</li>
                        <li><b>Azure Tech (Yggdrasil)</b> reserves the right to cancel or amend this promotion at any time.</li>
                        <li>All prizes from this campaign will be deducted from the monthly invoice of the respective operator. Note that when cash prizes are automatically awarded to players, the relevant prize is added to the player’s balance in the respective account currency. Deduction on theinvoices will be credited by <b>Azure Tech (Yggdrasil)</b> in accordance to the currency of the invoice (typically EUR), as per EUR values specifiedin the prize pool.</li>
                    </ol>
                    <h2>Promo Mechanics and details</h2>
                    <p>Participating in promotional activities, you agree to the following conditions:</p>
                    <ol>
                        <li>During the campaign, please place games in the top ten popular games on your website.</li>
                        <li>During the campaign, you can clearly display promotional banners on your website.</li>
                        <li>Promoting promotional activities must be through marketing methods such as email, SMS, chat or phone calls.</li>
                        <li>Clearly display the terms and conditions of this campaign on your website.</li>
                        <li>Start producing marketing materials and promote the campaign.</li>
                    </ol>
                </div>
            </div>
        )}
      </div>
    </>
  );
}