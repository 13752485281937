import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';

const FishingGallery = () => {
  const { t, i18n } = useTranslation();
  const [activeImage, setActiveImage] = useState(null);

  const images = [
    { id: 1, src: '/images/fishing/fishing2-logo.webp', alt: 'Fishing 2', btn: 'btn1', bg:'/images/fishing/fishing2-ss.webp'},
    { id: 2, src: '/images/fishing/sharkhunter-logo.webp', alt: 'Shark Hunter', btn: 'btn2', bg:'/images/fishing/sharkhunter-ss.webp' },
    { id: 3, src: '/images/fishing/paradise-logo.webp', alt: 'Paradise', btn: 'btn3', bg:'/images/fishing/paradise-ss.webp'},
    { id: 4, src: '/images/fishing/fishinggod-logo.webp', alt: 'Fishing God', btn: 'btn4', bg:'/images/fishing/fishinggod-ss1.webp'},
  ];

  const handleImageActive = (id) => {
    setActiveImage(id);
    const bgElement = document.getElementById('logoContainer');
    const activeButton = document.getElementById(images[id-1].btn);
    const activeLogo = document.getElementById(id);
    activeButton.style.opacity=1;
    activeLogo.style.opacity=1;
    bgElement.style.backgroundImage = images[id-1].bg;
  };

  const handleImageInactive = (id) => {
    setActiveImage(null);
    const activeButton = document.getElementById(images[id-1].btn);
    const activeLogo = document.getElementById(id);
    activeButton.style.opacity=0;
    activeLogo.style.opacity=0;
  };
  return (
    <div className="fishing-area">
              <div id = 'logoContainer' className='logo-Container'>
          <div className='fishing-logo'>
            <img id = '1' src='/images/fishing/fishing2-hero-img-1.webp' style={{opacity:0}} alt = "Fishing 2"/>
          </div>
          <div className='fishing-logo'>
            <img id = '2' src='/images/fishing/sharkhunter-hero-img-1.webp' style={{opacity:0}} alt = "Shark Hunter" />
          </div>
          <div className='fishing-logo'>
            <img id = '3' src='/images/fishing/paradise-hero-img-1.webp' style={{opacity:0}} alt = "Paradise"/>
          </div>
          <div className='fishing-logo'>
            <img id = '4' src='/images/fishing/fishinggod-hero-img-1.webp' style={{opacity:0}} alt = "Fishing God"/>
          </div>
</div>
      {images.map((image) => (
        <div>
        <div
          key={image.id}
          className={`fish-selection-item ${activeImage === image.id ? 'active' : ''}`}
          onMouseEnter={() => handleImageActive(image.id)}
          onMouseLeave={() => handleImageInactive(image.id)}
        >
          <img src={image.src} alt={image.alt} />
          <div>
          <button className='red-btn' id = {image.btn} style={{opacity:0}}>{t('button.1')}</button>
          </div> 
        </div>

        </div>
      ))}
    </div> 
            
  );
};

export default FishingGallery;