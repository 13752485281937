import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { useTranslation } from "react-i18next";

export default function PromotionPage() {
  const { t, i18n } = useTranslation();
  const title = "Reward Points";
  const [sliderCurrentPage, setSliderCurrentPage] = useState("RewardPoints");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("RewardPoints");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet: Reward Points</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8Bet with our reward points. Exchange Reward Points into game credits or other bonuses and prizes!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

        <div className="main-wrapper-promo">
          <div className="main-content-area">
            <h2>{t("promotion.1.title")}</h2>
            <p>{t("promotion.1.content.desc.1")}</p>
            <p>{t("promotion.1.content.desc.2")}</p>
            <br />
            <h2>{t("promotion.1.content.title.1")}</h2>

            <div className="table-main">
              <table>
                <thead>
                  <th>{t("promotion.1.content.table.label.2")}</th>
                  <th>{t("promotion.1.content.table.label.5")}</th>
                  <th>{t("promotion.1.content.table.label.6")}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>1,000</td>
                    <td>10,000</td>
                    <td>5.00</td>
                  </tr>
                  <tr>
                    <td>10,000</td>
                    <td>100,000</td>
                    <td>50.00</td>
                  </tr>
                  <tr>
                    <td>100,000</td>
                    <td>1,000,000</td>
                    <td>500.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              {t("promotion.1.content.desc.3")}
            </p>
            <div className="table-main">
              <table>
                <thead>
                  <th colspan="3" className="red-header">
                    Website A
                  </th>
                </thead>
                <thead>
                  <th>{t("promotion.1.content.table.label.1")}</th>
                  <th>{t("promotion.1.content.table.label.2")}</th>
                  <th>{t("promotion.1.content.table.label.3")}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("promotion.1.content.table.data.a.promo.1")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.1")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.1")}</td>
                  </tr>
                  <tr>
                    <td>{t("promotion.1.content.table.data.a.promo.2")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.2")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.2")}</td>
                  </tr>
                  <tr>
                    <td>{t("promotion.1.content.table.data.a.promo.3")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.3")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.3")}</td>
                  </tr>
                  <tr>
                  <td colspan="2">
                      <strong>{t("promotion.1.content.table.data.a.promo.4")}</strong>
                    </td>
                    <td>
                      <strong>
                        {t("promotion.1.content.table.data.a.credits.4")}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-main">
              <table>
                <thead>
                  <th colspan="3" className="red-header">
                    Website B
                  </th>
                </thead>
                <thead>
                  <th>{t("promotion.1.content.table.label.1")}</th>
                  <th>{t("promotion.1.content.table.label.2")}</th>
                  <th>{t("promotion.1.content.table.label.3")}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Rebate 0.20% on Sports</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.1")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.5")}</td>
                  </tr>
                  <tr>
                    <td>Rebate 0.30% on Casinos</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.2")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.4")}</td>
                  </tr>
                  <tr>
                    <td>Rebate weekly & conditions</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.3")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.3")}</td>
                  </tr>
                  <tr>
                  <td colspan="2">
                      <strong>{t("promotion.1.content.table.data.a.promo.4")}</strong>
                    </td>
                    <td>
                      <strong>
                        {t("promotion.1.content.table.data.a.credits.4")}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-main">
              <table>
                <thead>
                  <th colspan="3" className="red-header">
                    Website C
                  </th>
                </thead>
                <thead>
                  <th>{t("promotion.1.content.table.label.1")}</th>
                  <th>{t("promotion.1.content.table.label.2")}</th>
                  <th>{t("promotion.1.content.table.label.3")}</th>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("promotion.1.content.table.data.c.promo.1")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.1")}</td>
                    <td>{t("promotion.1.content.table.data.a.credits.5")}</td>
                  </tr>
                  <tr>
                    <td>{t("promotion.1.content.table.data.c.promo.2")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.2")}</td>
                    <td>{t("promotion.1.content.table.data.a.turnover.4")}</td>
                  </tr>
                  <tr>
                    <td>{t("promotion.1.content.table.data.c.promo.3")}</td>
                    <td>{t("promotion.1.content.table.data.c.turnover.3")}</td>
                    <td>
                      <strong>{t("promotion.1.content.table.data.c.credits.3")}</strong>
                    </td>
                  </tr>
                  <tr>
                  <td>{t("promotion.1.content.table.data.c.promo.4")}</td>
                  <td>{t("promotion.1.content.table.data.c.turnover.4")}</td>
                  <td><strong>{t("promotion.1.content.table.data.c.credits.4")}</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <strong>{t("promotion.1.content.table.data.a.promo.4")}</strong>
                    </td>
                    <td>
                      <strong>
                       {t("promotion.1.content.table.data.a.credits.4")}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              <strong>
                {t("promotion.1.content.desc.4")}
              </strong>
            </p>
            <br />
            <h2>{t("promotion.1.content.title.2")}</h2>
            <ul>
              <li>
                {t("promotion.1.content.t&c.1")}
              </li>
              <li>
                {t("promotion.1.content.t&c.2")}
              </li>
              <li>
                {t("promotion.1.content.t&c.3")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
