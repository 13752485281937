import React, { useEffect, useState } from 'react';
import { apiJSONURL } from '../pages/LiveCasino';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import "../style/accountStyle.css";
import AccountSelection from "../snippets/AccountSelection";
import { use } from 'i18next';


function ReferReport() {
    const { t, i18n } = useTranslation();
    const [showTable, setShowTable] = useState(false); 
    //const storedUsername = localStorage.getItem('encryptedUsername');
    const storedUserName = localStorage.getItem('username');

    useEffect(() => {
        if (!storedUserName) {
            throw new Error("Username not found in localStorage");
        }

        $("input[name='fd'], input[name='td']").val(getCurrentDate());

        $("#rp-searchBtn").click(function() {
            const fd = $("input[name='fd']").val();
            const td = $("input[name='td']").val();
            getRequestReport(fd, td);
        });

        function getRequestReport(fd, td) {
            setShowTable(true);  

            $("#rp-reportList").html("<td colspan='6'>Loading...</td>");
            $("#rp-searchBtn").attr("disabled", "disabled");
            const currLang = i18n.language;
            $.post(apiJSONURL, $("#rp-requestForm").serialize() + "&cmd=getReferralHistory&u=" + storedUserName+ "&l=" + currLang, function(d2) {
                const result2 = $.parseJSON(d2);
                let html = "";
                if ($.isEmptyObject(result2)) {
                    html += "<tr class='reportResult'>";
                    html += "<td colspan='6'>No record found.</td>";
                    html += "</tr>";
                } else {
                    $.each(result2, function(k, v) {
                        const vstring = v.split("|");
                        const datetime = vstring[0];
                        const username = vstring[1];
                        const winnings = vstring[2];
                        const credits = vstring[3];
                        const startCredits = vstring[4];
                        const endCredits = vstring[5];
                        html += "<tr class='reportResult'>";
                        html += "<td>" + datetime + "</td>";
                        html += "<td>" + username +"</td>";
                        html += "<td>" + winnings +"</td>";
                        html += "<td>" + credits +"</td>";
                        html += "<td>" + startCredits +"</td>";
                        html += "<td>" + endCredits +"</td>";
                        html += "</tr>";
                    });
                }

                $("#rp-reportList").html(html);
                $("#rp-searchBtn").removeAttr("disabled");
            });
        }

        function cancelPendingDeposit(username, id) {
            $.post(apiJSONURL, { cmd: "cancelPendingDeposit", u: username, id: id }, function(d) {
                const result = $.parseJSON(d);
                if (result.s === "in") {
                    $.post(apiJSONURL, { cmd: "getToken", u: username }, function(d2) {
                        const fd = $("input[name='fd']").val();
                        const td = $("input[name='td']").val();
                        getRequestReport(fd, td);
                    });
                } else {
                    console.error("Failed to cancel pending deposit:", result.message);
                }
            });
        }

        $(document).on('click', '.cancelBtn', function() {
            const username = $(this).data('username');
            const id = $(this).data('id');
            cancelPendingDeposit(username, id);
        });

        function getCurrentDate() {
            const d = new Date();
            const month = d.getMonth() + 1;
            const day = d.getDate();

            const output = d.getFullYear() + '-' +
                (month < 10 ? '0' : '') + month + '-' +
                (day < 10 ? '0' : '') + day;

            return output;
        }

        return () => {
            $("#rp-searchBtn").off("click");
            $(document).off('click', '.cancelBtn');
        };
    }, [storedUserName]);

    return (
        <>
            <div className="account-content report-page">
            <AccountSelection />
                <div className="account-container tier-info">
                    <div className="report-info">
                        <h2>{t('report.title.4')}</h2>
                        <div className="title-line"></div>
                        <div>
                            <p className="referral-text">{t('report.desc.4')}</p>
                        </div>
                    </div>
                    <div className="table-fields">
                        <form id="rp-requestForm">
                            <div className="from-to-type-container">
                                <div className="field-row">
                                    <label>{t('label.16')}:</label>
                                    <input type="date" name="fd" />
                                </div>
                                <div className="field-row">
                                    <label>{t('label.17')}:</label>
                                    <input type="date" name="td" />
                                </div>
                                <div className="field-row">
                                    <button id="rp-searchBtn" className="yellow-btn" type="button">
                                        <i className="fa fa-search"></i>{t('header.5')}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {showTable && (
                        <div className="summary">
                            <h3>Summary</h3>
                            <div>
                                <table>
                                    <thead id="tableHeading">
                                        <tr>
                                            <th>Date</th>
                                            <th>Username</th>
                                            <th>Winnings</th>
                                            <th>Credits</th>
                                            <th>Start Credits</th>
                                            <th>End Credits</th>
                                        </tr>
                                    </thead>
                                    <tbody id="rp-reportList"></tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="main-content-wrapper"></div>
        </>
    );
}

export default ReferReport;
