import React, { useState } from 'react';

const upload = process.env.REACT_APP_UPLOAD;

const UploadPhotoForm = () => {
    const [uploadMessage, setUploadMessage] = useState('');

    const handleUpload = async (event) => {
        event.preventDefault();

        const form = event.target;
        const formData = new FormData(form);
        // Retrieve username from local storage
        const username = localStorage.getItem('username')?.trim().toUpperCase() || '';
        formData.append('u', username);
        formData.append('pum_form_popup_id', '4137');

        try {
            const response = await fetch(upload, {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();
            if (json.e) {
                setUploadMessage(`Error! ${json.e}`);
            } else if (json.s) {
                setUploadMessage(`Success! ${json.s}`);
            }
        } catch (error) {
            setUploadMessage('Error! Could not upload the photo.');
        }
    };

    return (
        <>
            <form id="uploadPhotoForm" onSubmit={handleUpload} encType="multipart/form-data">
                <h3 style={{ fontSize: '1.2em', marginBottom: '12px', fontWeight: 'bold' }}>Upload your ID photo</h3>
                <div className="instruction"><i>Size limit: 5MB, JPG or PNG Only</i></div>
                <div className="formrow">
                    <label htmlFor="imgFront">Upload ID Photo</label>
                    <input type="file" id="imgFront" name="imgFront" accept=".jpg,.png" />
                </div>
                <div className="formrow">
                    <label htmlFor="imgSelfie">Upload Selfie with ID</label>
                    <input type="file" id="imgSelfie" name="imgSelfie" accept=".jpg,.png" />
                </div>
                <div id="uploadPhotoMsg">{uploadMessage}</div>
                <button id="uploadPhotoBtn" type="submit">SUBMIT</button>
            </form>
        </>
    );
};

export default UploadPhotoForm;