import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import { currentPath, currentDomain } from '../App';

export const tokenURL = process.env.REACT_APP_TOKEN_URL;
export const wlpLaunchURL = process.env.REACT_APP_WLP_LAUNCH_URL;
export const httpsLaunchURL = process.env.REACT_APP_HTTPS_LAUNCH_URL;
export const apiJSONURL = process.env.REACT_APP_API_JSON_URL;
export const totalFrames = 77;


export const launchGame = async (
  url,
  gameId,
  isMobile,
  lang,
  transfer,
  isHttps,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert,
  t 
) => {
  //let username = localStorage.getItem('encryptedUsername');
  let username = localStorage.getItem("username");
  let launchURL = url;

  setLaunching(true);
  setCurrentFrame(19);
  setAnimationComplete(false);

  if (document.body.classList.contains("isLoggedIn")) {
    try {
      const tokenResponse = await axios.post(
        apiJSONURL,
        `cmd=getToken&u=${username}`
      );
      const tokenResult = tokenResponse.data;
      username = tokenResult.token;

      const gameTokenResponse = await axios.post(
        tokenURL,
        `u=${username}&g=${gameId}&t=${transfer}&m=${mobile}`
      );
      const gameTokenResult = gameTokenResponse.data;
      const sessionToken = gameTokenResult.token;

      if (gameTokenResult.e) {
        showAlert(gameTokenResult.emsg);
        setLaunching(false);
        return;
      }

      launchURL =
        isHttps && !gameId.startsWith("33")
          ? `${httpsLaunchURL}?s=${sessionToken}&l=${lang}`
          : `${wlpLaunchURL}?s=${sessionToken}&l=${lang}`;

      if (mobile) {
        launchURL += `&m=1&t=1&h=${encodeURIComponent(
        window.location.protocol +
          "//" +
          window.location.hostname +
          window.location.pathname
        )}`;
      } 

      if (gameId.startsWith("47")) {
        launchURL += "&I=0";
      }
      if (gameId.startsWith("58") && !isMobile) {
        launchURL += `&h=${encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.hostname +
            window.location.pathname
        )}`;
      }

      if (!gameId.startsWith("25") && !gameId.startsWith("62")) {
        handleRecentGamesCookie(gameId);
      }

      if (document.querySelector(".gameIframe")?.style.display === "block") {
        document.querySelector(".gameIframe").src = "";
      }

      if (!launchURL) {
        showAlert(t('msg.1')); 
      } else {
        handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
      }
      setLaunching(false);
    } catch (error) {
      console.error(error);
      setLaunching(false);
    }
  } else {
    if (!username && !launchURL) {
       showAlert(t('msg.1')); 
    } else {
      handleGameLaunch(launchURL, isMobile, gameId, lang, showAlert);
    }
  }
};
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
let mobile = isMobile ? 1 : 0;
const handleGameLaunch = (launchURL, isMobile, gameId, lang, showAlert, t) => {
  if (isMobile) {
    window.top.location.href = launchURL;
  } else {
    if (gameId.startsWith("62")) {
      const win = window.open(launchURL, "popup", "width=1024,height=768");
      if (win) {
        win.focus();
      } else {
        showAlert(t('msg.2'));
      }
    } else {
      const win = window.open(launchURL, "_blank", "toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600");
      if (win) {
        win.focus();
      } else {
        showAlert(t('msg.2'));
      }
    }
  }
};


const handleRecentGamesCookie = (gameId) => {
  const cookieName = "recentGames";
  let cookies = getCookie(cookieName);
  if (!cookies) {
    setCookie(cookieName, gameId, 30);
  } else {
    if (cookies.includes(gameId)) {
      cookies = cookies.replace(`,${gameId}`, "").replace(`${gameId},`, "");
      cookies = `${gameId},${cookies}`;
    } else {
      cookies = `${gameId},${cookies}`;
      const cookiesArray = cookies.split(",");
      if (cookiesArray.length > 5) {
        cookies = cookiesArray.slice(0, 5).join(",");
      }
    }
    setCookie(cookieName, cookies, 30);
  }
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const LiveCasino = () => {
  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const showAlert = (message) => {
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame((prevFrame) => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 25);
    }
    return () => clearInterval(interval);
  }, [launching]);



  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>SG8Bet</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="Experience the best online live casino in 2024 with SG8Bet. Play Evolution Games – SA Gaming – AFB Gaming and more!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>

      <div className="page-headlines-container">
        <div className="pageHeaderText">
          <h1>{t('titles.1')}</h1>
        </div>
      </div>

      <div className="liveCasino">
        <Game
          imgSrc="/images/livedealer/world-dealer.webp"
          imgSrc2="/images/livedealer/world-entertainment.webp"
          altText="Live casino powered by World Entertainment"
          gameId={mobile ? "20002" : "20001"}
          gameName="World Entertainment"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          label={t('header.8')}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/evolution.webp"
          imgSrc2="/images/livedealer/evolution-logo.webp"
          altText="Live casino powered by evolution"
          gameId="25000"
          gameName="Evolution"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          label={t('header.8')}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/ae-dealer.webp"
          imgSrc2="/images/livedealer/ae-logo.webp"
          altText="live casino powered by SA Gaming"
          gameId="46001"
          gameName="AE Live Casino"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          label={t('header.8')}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/all_bet.webp"
          imgSrc2="/images/livedealer/allbet_logo.webp"
          altText="Live casino powered by AllBet"
          gameId="19000"
          gameName="AllBet Live Casino"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/game_play.webp"
          imgSrc2="/images/livedealer/gp_logo.webp"
          altText="live casino powered by Gameplay"
          gameId="15100"
          gameName="Gameplay Live Casino"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/sbobet-live.webp"
          imgSrc2="/images/livedealer/sbobet-logo.webp"
          altText="SBOBet Live Casino"
          gameId="70201"
          gameName="Sbobet"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/sa_gaming.webp"
          imgSrc2="/images/livedealer/sa_gaming-logo.webp"
          altText="live casino powered by SA Gaming"
          gameId="36001"
          gameName="SA Gaming Live Casino"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/og-dealer.webp"
          imgSrc2="/images/livedealer/og-logo.webp"
          altText="OGPlus Live Casino"
          gameId="88001"
          gameName="OG Plus"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
        <Game
          imgSrc="/images/livedealer/pragmatic.webp"
          imgSrc2="/images/livedealer/pragmatic-logo.webp"
          altText="Pragmatic Play Live Casino"
          gameId="53900"
          gameName="Pragmatic Play"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />

        <Game
          imgSrc="/images/livedealer/popok.webp"
          imgSrc2="/images/livedealer/popok-logo.webp"
          altText="live casino powered by AFB"
          gameId="74001"
          gameName="Popok"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />

        <Game
          imgSrc="/images/livedealer/playtech.webp"
          imgSrc2="/images/livedealer/playtech-logo.webp"
          altText="PlayTech Live Casino"
          gameId="16502"
          gameName="Playtech"
          launchGame={launchGame}
          launching={launching}
          currentFrame={currentFrame}
          animationComplete={animationComplete}
          setLaunching={setLaunching}
          setCurrentFrame={setCurrentFrame}
          setAnimationComplete={setAnimationComplete}
          showAlert={showAlert}
          t={t}  
          openPopup={openPopup}
        />
      </div>

      <AlertModal open={alertOpen} close={() => setAlertOpen(false)} message={alertMessage} />

      <Popup open={popupOpen} closeOnDocumentClick onClose={closePopup} modal nested>
        <div className="popup-ok-content" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
        <div className="close" style={{color: "#111", fontWeight: "bold"}} onClick={closePopup}>
          x
        </div>
          <div className="content popup-ok">
            <div>
              <Game
                imgSrc="/images/livedealer/popok-roulette.webp"
                gameId="75301"
                gameName="Popok"
                launchGame={launchGame}
                launching={launching}
                currentFrame={currentFrame}
                animationComplete={animationComplete}
                setLaunching={setLaunching}
                setCurrentFrame={setCurrentFrame}
                setAnimationComplete={setAnimationComplete}
                showAlert={showAlert}
                t={t} 
                openPopup={openPopup}
              />
              <p>Roulette</p>
            </div>
            <div>
              <Game
                imgSrc="/images/livedealer/popok-multifruit.webp"
                gameId="75302"
                gameName="Popok"
                launchGame={launchGame}
                launching={launching}
                currentFrame={currentFrame}
                animationComplete={animationComplete}
                setLaunching={setLaunching}
                setCurrentFrame={setCurrentFrame}
                setAnimationComplete={setAnimationComplete}
                showAlert={showAlert}
                t={t} 
                openPopup={openPopup}
              />
              <p>Multi Fruit</p>
            </div>
            <div>
              <Game
                imgSrc="/images/livedealer/popok-diamondfruits.webp"
                gameId="75303"
                gameName="Popok"
                launchGame={launchGame}
                launching={launching}
                currentFrame={currentFrame}
                animationComplete={animationComplete}
                setLaunching={setLaunching}
                setCurrentFrame={setCurrentFrame}
                setAnimationComplete={setAnimationComplete}
                showAlert={showAlert}
                t={t} 
                openPopup={openPopup}
              />
              <p>Diamond Fruits</p>
            </div>
          </div>
          
        </div>
      </Popup>
    </>
  );
};

const Game = ({
  imgSrc,
  imgSrc2,
  imgBg,
  altText,
  gameId,
  gameName,
  launchGame,
  launching,
  currentFrame,
  animationComplete,
  setLaunching,
  setCurrentFrame,
  setAnimationComplete,
  showAlert,
  label,
  t,
  openPopup,
}) => {
  const imageRef = useRef(null);
  const isLoggedIn = document.body.classList.contains("isLoggedIn");

const handleGameClick = () => {
  if (gameId === "74001") {
    openPopup();
  } else {
    launchGame(
      "",
      gameId,
      isMobile,
      t('lang.1'),
      0,
      true,
      setLaunching,
      setCurrentFrame,
      setAnimationComplete,
      showAlert,
      t
    );
  }
};

  return (
    <>
      <div className="liveCasinoContent">
        {launching && isLoggedIn && (
          <div className="overlayStyle">
            <div className="loadingBar">
              <div className={`spinner ${animationComplete ? "pop" : ""}`}>
                <img
                  ref={imageRef}
                  src={`/images/frameLoading/sg8logo_${String(
                    currentFrame
                  ).padStart(5, "0")}.png`}
                  alt="Loading..."
                />
              </div>
            </div>
          </div>
        )}
        <div className="liveContainer">
          {label && <div className="label-lc">{label}</div>}
          <img className="liveCasinoLogo" src={imgSrc2} alt={altText} />
          <img className="charMain" src={imgSrc} alt={altText} />
          <div className="liveCasinoBtn">
            <div
              className="hidden-xs yellow-btn"
              onClick={handleGameClick}
            >
              {t('button.1')}
            </div>
            <div
              className="visible-xs yellow-btn lcBtn"
              onClick={handleGameClick}
            >
              {t('button.1')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AlertModal = ({ open, close, message }) => (
  <Popup open={open} closeOnDocumentClick onClose={close} modal nested>
    <div className="popupError" style={{ backgroundImage: `url('/images/registration-form-bg.jpg')` }}>
      <img src="/images/live-dealer-mobile-icon.webp" alt="" />
      <div className="content">{message}</div>
      <div className="actions">
        <button className="yellow-btn" onClick={close}>OK</button>
      </div>
    </div>
  </Popup>
);

export default LiveCasino;
