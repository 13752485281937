import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [imageSrc, setImageSrc] = useState('');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (lng === 'en') {
      setMessage('You are now browsing in English');
      setImageSrc('/images/ukflag.png');
    } else if (lng === 'th') {
      setMessage('คุณกำลังใช้งานเว็บไซต์เป็นภาษาไทย');
      setImageSrc('/images/thaiflag.png');
    }
    setOpen(true);
  };

  const closeModal = () => setOpen(false);

  return (
    <div className='lang-switcher'>
      <a onClick={() => changeLanguage('en')}>
        <img src='/images/ukflag.png' alt='English' />
      </a>
      <a onClick={() => changeLanguage('th')}>
        <img src='/images/thaiflag.png' alt='Thai' />
      </a>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className='popUpContent translationContent'>
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <div className="content">
            <img src={imageSrc} alt={message} />
            <p>{message}</p>
            <button className='yellow-btn' onClick={closeModal}>{t("errorsMsg.2")}</button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default LanguageSwitcher;
