import React, { useState, useEffect } from "react";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import { currentPath, currentDomain } from "../../App";
import { useTranslation } from "react-i18next";

export default function Sboweeklymission() {
  const { t, i18n } = useTranslation();
  const [sliderCurrentPage, setSliderCurrentPage] = useState("Sboweeklymission");
  const [bannerCurrentPage, setBannerCurrentPage] = useState("Sboweeklymission");
  const [loading, setLoading] = useState(true);
  const title = "SBO Weekly Mission";
  // const eventLink = t("promotion.11.link");
  
  // const handleLinkClick = () => {
  //   window.open(eventLink);
  //   };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 70);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SG8Bet: SBO Weekly Mission</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta
          name="description"
          content="SG8Bet with SBO Weekly Mission!"
        />
        <link rel="canonical" href={`${currentDomain}${currentPath}`} />
      </Helmet>
      <div className="promotionPage">
        <BackButton name={title} />
        <MainSlider
          sliderCurrentPage={sliderCurrentPage}
          bannerCurrentPage={bannerCurrentPage}
        />

        <div className="main-wrapper-promo">
          <div className="main-content-area">
            <h2>{t("promotion.11.title.1")}</h2>
            <p>
              {t("promotion.11.desc.4")}
            </p>
            <br />

            <h2>{t("promotion.11.title.2")}</h2>
            <ul>
              <li>{t("promotion.11.desc.1")}</li>
              <li>{t("promotion.11.desc.2")}</li>
              <li>{t("promotion.11.desc.3")}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}