import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';
import PaymentMethod from '../../snippets/PaymentMethod';
import {currentPath, currentDomain} from '../../App';
import { useTranslation } from 'react-i18next';

function Spadegaming() {
  const [currentFilter, setCurrentFilter] = useState('all');
  const [sliderCurrentPage, setSliderCurrentPage] = useState('Spadegaming');
  const [bannerCurrentPage, setBannerCurrentPage] = useState('Spadegaming');
  const [searchQuery, setSearchQuery] = useState("");
  const { t, i18n } = useTranslation();

  const spadeFilterOptions = [
        { value: 'all', label: t("filter.1") },
        { value: 'top', label: t("filter.8") },
        { value: 'featured', label: t("filter.2") },
        { value: 'slots', label: t("filter.3") },
  ];
  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>SG8Bet</title> 
                <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
                <meta name="description" content="Experience premier online slot games of 2024 with Spadegaming at SG8Bet. Enjoy top-quality slots and thrilling gameplay."/>
                <link rel="canonical" href={`${currentDomain}${currentPath}`} />
            </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
          <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
          <div className='responsiveSticky'>
            <SlotProviderList initialProvider="spadegaming" />
            <div className="slot-filter-search-wrapper">
              <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
              <div className="filter-slots">
                <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={spadeFilterOptions} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <GameList provider="spade" filter={currentFilter} searchQuery={searchQuery} />
    </>
  );
}

export default Spadegaming;
