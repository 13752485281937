class DataEncrypt {
    constructor() {
      this.strSplit = ["", "g", "h", "G", "k", "g", "J", "K", "I", "h", "i", "j", "H"];
      this.n = 0;
    }
  
    DecryptData(toDecrypt) {
      let decryptTemp = "";
      if (toDecrypt && toDecrypt !== "") {
        let s = this.replaceString(toDecrypt.replace(/\r\n/g, ""));
        let str = s.split(",");
  
        for (let i = 0; i < str.length; i++) {
          decryptTemp += this.encryptToString(str[i]);
        }
      }
      return decryptTemp;
    }
  
    encryptToString(number) {
      return String.fromCharCode(parseInt(number, 16));
    }
  
    replaceString(st) {
      let s = st;
      for (let i = 1; i < this.strSplit.length; i++) {
        s = s.replace(new RegExp(this.strSplit[i], 'g'), ",");
      }
      return s;
    }
  }
  
  export default new DataEncrypt();  