import ProviderSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const SlotProviderList = ({ initialProvider }) => {
  // State to keep track of the active slide
  const [activeSlide, setActiveSlide] = useState(0);

  // Array of provider details
  const providers = [

    { id: 'bng', logo: '/images/slots/bng-logo.webp', name: 'Booongo', href:"/slots/bng/", isPromo: false },
    { id: 'pragmaticplay', logo: '/images/slots/small-logo/pp.webp', name: 'pragmaticplay', href:"/slots/pragmatic-play/", isPromo: true },
    { id: 'pgsoft', logo: '/images/slots/small-logo/pg-soft-logo-small.webp', name: 'PG Soft', href:"/slots/pgsoft/", isTop: true },
    { id: 'rtg', logo: '/images/slots/small-logo/rtg-logo-small.webp', name: 'Real Time Gaming', href:"/slots/rtg/", isTop: true },
    { id: 'jili', logo: '/images/slots/jili-logo.webp', name: 'JILI', href:"/slots/jili/", isPromo: false },
    { id: 'advantplay', logo: '/images/slots/small-logo/advantplay-logo.webp', name: 'advantplay', href:"/slots/advantplay/" },
    { id: 'betsoft', logo: '/images/slots/small-logo/betsoft-logo.webp', name: 'betsoft', href:"/slots/betsoft/" },

    { id: 'cq9', logo: '/images/slots/small-logo/cq9-logo-small.webp', name: 'CQ9', href:"/slots/cq9/" },
    { id: 'dragongaming', logo: '/images/slots/small-logo/dragongaming-logo.webp', name: 'dragongaming', href:"/slots/dragongaming/" },
    { id: 'evolution', logo: '/images/slots/small-logo/evolution-logo.webp', name: 'evolution', href:"/slots/evolution/" },
    { id: 'gameplay', logo: '/images/slots/small-logo/gameplay-logo.webp', name: 'gameplay', href:"/slots/gameplay/" },

    { id: 'kingmidas', logo: '/images/slots/small-logo/km-logo.webp', name: 'kingmidas', href:"/slots/kingmidas/" },
    { id: 'mannaplay', logo: '/images/slots/small-logo/mannaplay-logo.webp', name: 'mannaplay', href:"/slots/mannaplay/" },
    { id: 'microgaming', logo: '/images/slots/small-logo/microgaming-logo.webp', name: 'microgaming', href:"/slots/microgaming/" },
    { id: 'netent', logo: '/images/slots/small-logo/netent-logo-small.webp', name: 'NetEnt', href:"/slots/netent/" },
    { id: 'playson', logo: '/images/slots/small-logo/playson-logo-small.webp', name: 'Playson', href:"/slots/playson/" },
    { id: 'playstar', logo: '/images/slots/small-logo/playstar-logo-small.webp', name: 'Playstar', href:"/slots/playstar/" },
    { id: 'popok', logo: '/images/slots/small-logo/popok-logo.webp', name: 'popok gaming', href:"/slots/popok/" },
    { id: 'redtiger', logo: '/images/slots/small-logo/red-tiger-logo-small.webp', name: 'Red Tiger', href:"/slots/redtiger/" },
    { id: 'spadegaming', logo: "/images/slots/small-logo/spadegaming-logo-small.webp", name: 'Spade Gaming', href:"/slots/spadegaming/" },
    { id: 'ttg', logo: '/images/slots/small-logo/ttg-logo.webp', name: 'ttg', href:"/slots/ttg/" },
    { id: 'yggdrasil', logo: '/images/slots/small-logo/ygg-logo-small.webp', name: 'yggdrasil', href:"/slots/ygg/" },
    { id: 'yl', logo: '/images/slots/small-logo/yl-logo.webp', name: 'yl', href:"/slots/yl/" },
    { id: 'playtech', logo: '/images/slots/small-logo/playtech-logo.webp', name: 'playtech', href:"/slots/playtech/" },
    { id: 'btg', logo: '/images/slots/small-logo/btg-logo-small.webp', name: 'btg', href:"/slots/btg/" },
    // Add other providers here...
  ];

  // Find the index of the initial provider
  const initialIndex = providers.findIndex(p => p.id === initialProvider);

  const settings = {
    infinite: true,
    autoplay: false,
    centerMode: true,
    centerPadding: '70px',
    slidesToShow: 7,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: initialIndex,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
          breakpoint: 1080,
          settings: {
              slidesToShow: 5,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 900,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 1
          }
      },
      {
          breakpoint: 840,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }
    ]
  };

  useEffect(() => {
    setActiveSlide(initialIndex);
  }, [initialIndex]);

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="slot-provider-container">
        <ProviderSlider {...settings}>
          {providers.map((provider, index) => (
            <div className={`slick-provider ${index === activeSlide ? 'active' : ''}`} key={provider.id}>
              {provider.isTop && <div className='slider-label'>{t('header.8')}</div>}
              {provider.isPromo && <div className='promotion-label'>{t('header.10')}</div>}
              <a href={provider.href}>
                <img src={provider.logo} alt={`${provider.name} Logo`} loading="lazy"/>
              </a>
            </div>
          ))}
        </ProviderSlider>
      </div>
      <div className="provider-list-view-all-btn">
        <a href='/slots/' className='yellow-btn providerListBtn'>{t('header.6')}</a>
      </div>
    </>
  );
}

export default SlotProviderList;
