import React from 'react';
import { useTranslation } from "react-i18next";

const SlotSearchList = ({ searchQuery, setSearchQuery }) => {
    const { t } = useTranslation();
    return (
        <div className="slot-search-box">
            <input 
                className="searchBox" 
                placeholder={t('header.5')} 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
            />
        </div>
    );
};

export default SlotSearchList;
