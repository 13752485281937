import React from 'react'
import { useTranslation } from 'react-i18next';
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
    localStorage.removeItem('encryptedUsername');
    localStorage.removeItem('modalShown');
    window.location.href = "/"; // Redirect to homepage after logout
  };
export default function LogoutFunction() {
  const { t, i18n } = useTranslation();
  return (
    <a className='logout' onClick={handleLogout}>
        <img src="/images/logout.webp" alt="logout icon"/>
        <p>{t('label.7')}</p>
    </a>
  )
}