import React from 'react';
import Sliders from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const ProviderListHome = () => {
    const providers = [
        { name: 'evolution', image: '/images/slots/small-logo/evolution-logo.webp', link: '/slots/evolution/' },
        { name: 'PG Soft', image: '/images/slots/small-logo/pg-soft-logo-small.webp', link: '/slots/pgsoft/' },
        { name: 'Pragmatic', image: '/images/slots/small-logo/pp.webp', link: '/slots/pragmatic-play/' },
        { name: 'BNG', image: '/images/slots/bng-logo.webp', link: '/slots/bng/' },
        { name: 'Btg', image: '/images/slots/btg-logo.webp', link: '/slots/btg/' },
        { name: 'RealTime Gaming', image: '/images/slots/small-logo/rtg-logo-small.webp', link: '/slots/rtg/' },
        { name: 'Spadegaming', image: '/images/slots/small-logo/spadegaming-logo-small.webp', link: '/slots/spadegaming/' },
        { name: 'Playstar', image: '/images/slots/small-logo/playstar-logo-small.webp', link: '/slots/playstar/' },
        { name: 'Jili', image: '/images/slots/jili-logo.webp', link: '/slots/jili/' },
        { name: 'Playson', image: '/images/slots/small-logo/playson-logo-small.webp', link: '/slots/playson/' },
        { name: 'NetEnt', image: '/images/slots/small-logo/netent-logo-small.webp', link: '/slots/netent/' },
        { name: 'CQ9', image: '/images/slots/small-logo/cq9-logo-small.webp', link: '/slots/cq9/' },
        { name: 'YGG', image: '/images/slots/small-logo/ygg-logo-small.webp', link: '/slots/ygg/' },
        { name: 'Gameplay', image: '/images/slots/small-logo/gameplay-logo.webp', link: '/slots/gameplay/' },
        { name: 'yl', image: '/images/slots/small-logo/yl-logo.webp', link: '/slots/yl/' },
        { name: 'popok', image: '/images/slots/small-logo/popok-logo.webp', link: '/slots/popok/' },
        { name: 'betsoft', image: '/images/slots/small-logo/betsoft-logo.webp', link: '/slots/betsoft/' },
        { name: 'advantplay', image: '/images/slots/small-logo/advantplay-logo.webp', link: '/slots/advantplay/' },
        { name: 'mannaplay', image: '/images/slots/small-logo/mannaplay-logo.webp', link: '/slots/mannaplay/' },
        { name: 'kingmidas', image: '/images/slots/small-logo/km-logo.webp', link: '/slots/kingmidas/' },
        { name: 'playtech', image: '/images/slots/small-logo/playtech-logo.webp', link: '/slots/playtech/' },
        { name: 'dragongaming', image: '/images/slots/small-logo/dragongaming-logo.webp', link: '/slots/dragongaming/' },
        { name: 'ttg', image: '/images/slots/small-logo/ttg-logo.webp', link: '/slots/ttg/' },
    ];

    const settingss = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1080,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 840,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Sliders {...settingss} className="provider-list-slider">
            {providers.map((provider, index) => (
                <div className="slide" key={index}>
                    <a href={provider.link}>
                        <img src={provider.image} alt={provider.name} />
                    </a>
                </div>
            ))}
        </Sliders>
    );
}

export default ProviderListHome;
