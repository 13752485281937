import React, { useState, useEffect } from 'react';
import { apiJSONURL } from './LiveCasino';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import qs from 'qs';

function Withdraw() {
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState({ userId: '', balance: null });
  const [loading, setLoading] = useState(false);
  const [bankIcon, setBankIcon] = useState('/images/bank icons/BBL.webp');
  const [balance, setBalance] = useState();
  const [amt, setAmount] = useState('');
  const [mac, setMac] = useState('');
  const [man, setMan] = useState('');
  const [mbc, setMbc] = useState('BANGKOK BANK PUBLIC COMPANY LTD.'); // Set initial value to one of the bank values
  const [alertMessage, setAlertMessage] = useState('');
  const [isDisabled,setIsDisabled] = useState(false);

  useEffect(() => {
    const userBalanceElement = document.getElementById('userBalance');

    if (userBalanceElement) {
      const updateBalance = () => {
        const balanceContent = userBalanceElement.textContent;
        setBalance(balanceContent);
      };
      updateBalance();
      const observer = new MutationObserver(updateBalance);
      observer.observe(userBalanceElement, { childList: true, subtree: true, characterData: true });
      return () => {
        observer.disconnect();
      };
    }

    // const username = localStorage.getItem('encryptedUsername');
    const username = localStorage.getItem('username');

    if (username) {
      const fetchBalance = async () => {
        try {
          const response = await fetch(`${apiJSONURL}?cmd=getBalance&u=${username}`);
          const data = await response.json();
          const balance = data.balance;
          setBalance(data.balance);
          setUserData(prevState => ({ ...prevState, userId: username, balance }));
        } catch (error) {
          console.error('Error fetching user balance:', error);
        }
      };

      const fetchRollover = async () => {
        try {
          const response = await fetch(`${apiJSONURL}?cmd=getRemainingRollover&u=${username}`);
          const data = await response.json();
          const rollover = data.rollover;
          console.log('Rollover data:', rollover);
          setUserData(prevState => ({ ...prevState, userId: username, rollover }));
        } catch (error) {
          console.error('Error fetching user rollover:', error);
        }
      };

      fetchBalance();
      fetchRollover();
    }
  }, []);

  const handleBankSubmit = async () => {
    setLoading(true);
    setIsDisabled(true);
    const username = localStorage.getItem('username');
    const amount = parseFloat(amt);
    const accountno = mac;

    if (!man) {
      setAlertMessage(t('msg.3'));
      setIsDisabled(false);
      setLoading(false);
      return;
    } else if (!mac) {
      setAlertMessage(t('msg.4'));
      setIsDisabled(false);
      setLoading(false);
      return;
    } else if (!amt) {
      setAlertMessage(t('msg.5'));
      setIsDisabled(false);
      setLoading(false);
      return;
    }
    try {
      if (amount > balance) {
        setAlertMessage(t('msg.6'));
        return;
      } else if (amount < 101) {
        setAlertMessage(t('msg.7'));
        return;
      } else if (amount > 49000) {
        setAlertMessage(t('msg.11'));
        return;
      }
      const response = await axios.post(apiJSONURL,
        qs.stringify({
          cmd: 'withdrawal-request',
          method: 'bankTHB',
          man: man,
          mac: accountno,
          mbc: mbc,
          u: username,
          amt: amount,
        }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });

      console.log('API response:', response);

      if (response.data.smsg) {
        setAlertMessage(response.data.smsg);
      } else if (response.data.emsg) {
        setAlertMessage(response.data.emsg);
      }
    } catch (error) {
      console.error('API call error:', error);
      setAlertMessage(t('msg.8'));
    } finally {
      setLoading(false);
      setIsDisabled(false);
    }
  };

  const banks = [
    { value: "BANGKOK BANK PUBLIC COMPANY LTD.", img: "/images/bank icons/BBL.webp", bankName: t('bank.1') },
    { value: "BANK OF AYUDHYA", img: "/images/bank icons/BAY.webp", bankName: t('bank.2')  },
    { value: "KRUNG THAI BANK PUBLIC COMPANY LTD.", img: "/images/bank icons/KTB.webp", bankName: t('bank.3')  },
    { value: "KASIKORNBANK PUBLIC COMPANY LIMITED", img: "/images/bank icons/KBANK.webp", bankName: t('bank.4')  },
    { value: "KIATNAKIN PHATRA BANK PUBLIC COMPANY LIMITED", img: "/images/bank icons/KKP.webp", bankName: t('bank.5')  },
    { value: "CITIBANK, N.A. (CITI), BANGKOK BRANCH", img: "/images/bank icons/CITI.webp", bankName: t('bank.6')  },
    { value: "CIMB (THAI) PUBLIC COMPANY LIMITED", img: "/images/bank icons/CIMB.webp", bankName: t('bank.7')  },
    { value: "SUMITOMO MITSUI BANKING CORPORATION (SMBC)", img: "/images/bank icons/SMBC.webp", bankName: t('bank.8')  },
    { value: "DEUTSCHE BANK AKTIENGESELLSCHAFT (DB)", img: "/images/bank icons/DB.webp", bankName: t('bank.9')  },
    { value: "TMB BANK PUBLIC COMPANY LIMITED", img: "/images/bank icons/TMB.webp", bankName: t('bank.10')  },
    { value: "TISCO BANK PUBLIC COMPANY LIMITED", img: "/images/bank icons/TISCO.webp", bankName: t('bank.11')  },
    { value: "THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED (TCRB)", img: "/images/bank icons/TCRB.webp", bankName: t('bank.12')  },
    { value: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.", img: "/images/bank icons/SCB.webp", bankName: t('bank.13')  },
    { value: "BANK FOR AGRICULTURE AND AGRICULTURAL CO-OPERATIVES", img: "/images/bank icons/BAAC.webp", bankName: t('bank.15')  },
    { value: "MIZUHO BANK,LTD.", img: "/images/bank icons/MHBK.webp", bankName: t('bank.16')  },
    { value: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED", img: "/images/bank icons/UOB.webp", bankName: t('bank.17')  },
    { value: "LAND AND HOUSES RETAIL BANK PUBLIC COMPANY LIMITED", img: "/images/bank icons/LH-BANK.webp", bankName: t('bank.18')  },
    { value: "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LTD.", img: "/images/bank icons/SCB.webp", bankName: t('bank.19')  },
    { value: "GOVERNMENT SAVING BANK", img: "/images/bank icons/GSB.webp", bankName: t('bank.20')  },
    { value: "GOVERNMENT HOUSING BANK", img: "/images/bank icons/GHB.webp", bankName: t('bank.21')  },
    { value: "ISLAMIC BANK OF THAILAND", img: "/images/bank icons/IBT.webp", bankName: t('bank.22')  },
    { value: "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI)", img: "/images/bank icons/ICBC.webp", bankName: t('bank.23')  },
    { value: "HONGKONG and SHANGHAI CORPORATION LTD.", img: "/images/bank icons/HSBC.webp", bankName: t('bank.24')  },
    { value: "Bank of China", img: "/images/bank icons/BOC.webp", bankName: t('bank.25')  },
  ];

  const handleSelectChange = (e) => {
    const selectedBank = e.target.value;
    setMbc(selectedBank);

    const selectedBankData = banks.find(bank => bank.value === selectedBank);
    setBankIcon(selectedBankData ? selectedBankData.img : '');
  };

  return (
    <>
      <div className="account-container">
        <div className="page-headlines-container">
          <div className="pageHeaderText">
            <h1>{t('titles.5')}</h1>
          </div>
        </div>
        <p className="remainingrollover" style={{ display: userData.rollover > 0 ? 'block' : 'none' }}>Remaining Rollover: <span id="remainingRollover-amt">{userData.rollover}</span></p>
        <div className='withdraw-container'>
          <div className='all-form-container'>
            <form id="withdrawalFormHelp2Pay">
              <div className="form-title">
                <div>
                  <img src="/images/bank-icon.webp" alt="Bank Account" />{t('label.14')}
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.3')}</div>
                <div className="input-container">
                  <div className='bankselection'>
                    <img bankicon src={bankIcon} alt="Bank Icon" />
                    <select value={mbc} onChange={handleSelectChange} name="mbc" className="form-control">
                      {banks.map(bank => (
                        <option key={bank.value} value={bank.value}>
                          {bank.bankName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.4')}</div>
                <div className="input-container">
                  <input className="form-control" value={man} onChange={(e) => setMan(e.target.value)} autoComplete="off" name="man" type="text" placeholder={t('label.4')} />
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.13')}</div>
                <div className="input-container">
                  <input className="form-control"
                    value={mac} 
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
                      setMac(value);
                    }}
                    autoComplete="off"
                    name="mac"
                    type="text"
                    placeholder={t('label.13')} />
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.5')}</div>
                <div className="input-container">
                  <div id="withdrawal-help2pay-balance" className="userBalance">{userData.balance !== null ? userData.balance : 'Loading...'}</div>
                </div>
              </div>
              <div className="row">
                <div className="hLabel">{t('label.6')}</div>
                <div className="input-container">
                  <input id="withdrawal-help2pay-amount" className="form-control" autoComplete="off" max="49000" maxLength="5" min="101" name="amt" required="required" type="number" placeholder={t('msg.9')} value={amt} onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
              <div className="row">
                <div className="input-container" colSpan="2" style={{ textAlign: "center", background: "transparent", borderBottom: "0px!important" }}>
                  <div className="alert alert-info fade in alert-dismissable" dangerouslySetInnerHTML={{ __html: alertMessage }}></div>
                  <button id="btn-help2pay-submit" className="yellow-btn" type="button" onClick={handleBankSubmit} disabled={isDisabled}>{t('header.7')}</button>
                </div>
              </div>
            </form>
          </div>
          {loading && <div className='loadingContainer'><img src="/images/sg8.gif" alt="Loading..." /><span>Processing...</span></div>}
        </div>
      </div>
    </>
  );
}

export default Withdraw;